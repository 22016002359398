/* eslint-disable react/no-unused-state, prefer-destructuring, dot-notation, prettier/prettier, object-shorthand, arrow-body-style, no-unused-vars */
import { InfoTooltipWithTrigger } from '@superset-ui/chart-controls';
import {
  css,
  supersetTheme,
  t,
  withTheme,
} from '@superset-ui/core';
import React, { useEffect, useState, memo } from 'react';
import { Col, Row } from 'src/components';
import SelectControl from 'src/explore/components/controls/SelectControl';
import { Gutter } from 'antd/lib/grid/row';
// @ts-ignore
import Alert from 'src/components/Alert';
import {
  ConditionOperatorEnum,
  HardThresholdConditionProps,
  SmartDetectionConditionProps,
  AnomalyDetectorDirectionEnum,
  SmartDetectionConditionEntity,
  HardThresholdConditionEntity,
  ChangeThresholdConditionProps,
  ChangeThresholdConditionEntity,
  MeasureSeriesItemProps,
  MeasureSeriesItemEntity,
  DimensionSeriesValueDetailEntity,
} from './signalAlertsTypes';

import SmartDetectionControl from './SmartDetectionControl/SmartDetectionControl';
import HardThresholdControl from './HardThresholdControl/HardThresholdControl';
import ChangeThresholdControl from './ChangeThresholdControl/ChangeThresholdControl';
import { ImportantAsterisk } from './ImportantAsterisk';
import { CustomCollapse } from './CustomCollapse';
import { 
  INFO_MESSAGE_SMART_DETECTION_PAY_ATTENTION,
  INFO_MESSAGE_CHANGE_THRESHOLD_DETECTION_PAY_ATTENTION,
  INFO_MESSAGE_HARD_THRESHOLD_DETECTION_PAY_ATTENTION,
} from './SignalAlertsMessageConfig'


const getPivotMeasureLabel = (measure:string, dimensionSeries?: DimensionSeriesValueDetailEntity[]) => {
  const blue = 'rgb(26, 133, 171)';
  if (dimensionSeries === undefined || dimensionSeries.length === 0) {
    return <><text style={{color: blue}}>{'[ '}{measure}{' ]'}</text></>;
  }
  const dimensionConditionList = []; 
  for (let i = 0; i < dimensionSeries.length; i += 1) {
    const dimensionSeiesValue = dimensionSeries[i];
    if ( i !== dimensionSeries.length - 1) {
      dimensionConditionList.push(<>{dimensionSeiesValue.columnName}{' = '}<span css={{color: blue}}>{dimensionSeiesValue.value}</span>{', '}</>);
    } else {
      dimensionConditionList.push(<>{dimensionSeiesValue.columnName}{' = '}<span css={{color: blue}}>{dimensionSeiesValue.value}</span></>);
    }
  }
  return <><text css={{color: blue}}>{'[ '}{measure}{' ]'}</text>{', '}{dimensionConditionList}</>;
}


const MeasureSeriesItemControl = memo(function MeaesureSeriesItemControl(props: MeasureSeriesItemProps) {
  const DETECTION_TYPE_OPTIONS = [
    {label: "Smart Detection", value: "smart"},
    {label: "Hard Threshold", value: "hard"},
    {label: "Change Threshold", value: "change"}
  ];

  const getDetectionIntroductionRenderInfoArea = () => {
    return <span
      css={{
        display: 'inline-flex',
        flex: 'auto',
        marginTop: theme.gridUnit,
        marginLeft: theme.gridUnit * 2,
        // eslint-disable-next-line theme-colors/no-literal-colors
        color: 'rgb(10, 131, 218)',
        fontSize: '10pt',
      }}
    >
      {props?.wholeMetricConfiguration?.smartDetectionCondition &&
        INFO_MESSAGE_SMART_DETECTION_PAY_ATTENTION
      }
      {props?.wholeMetricConfiguration?.hardThresholdCondition &&
        INFO_MESSAGE_HARD_THRESHOLD_DETECTION_PAY_ATTENTION
      }
      {props?.wholeMetricConfiguration?.changeThresholdCondition &&
        INFO_MESSAGE_CHANGE_THRESHOLD_DETECTION_PAY_ATTENTION
      }
    </span>;
  }
  
  const getDetectionTypeTooltip = () => {
    if (props?.wholeMetricConfiguration?.smartDetectionCondition) {
      return t('Anomaly detection with multiple ML based algorithms.');
    }

    if (props?.wholeMetricConfiguration?.hardThresholdCondition) {
      return t('Outside the range defined by MAX/MIN will be treated as an anomaly.');
    }

    return t('Use the previous point value to determine if this point is an anomaly.');
  }

  const getDetectionDocLink = () => {
    if (props?.wholeMetricConfiguration?.smartDetectionCondition) {
      return 'https://onedrive.visualstudio.com/ODSP%20Wiki/_wiki/wikis/OSDP-Wiki.wiki/96670/Smart-Detection';
    }

    if (props?.wholeMetricConfiguration?.hardThresholdCondition) {
      return 'https://onedrive.visualstudio.com/ODSP%20Wiki/_wiki/wikis/OSDP-Wiki.wiki/96672/Hard-Threshold-Detection';
    }

    return 'https://onedrive.visualstudio.com/ODSP%20Wiki/_wiki/wikis/OSDP-Wiki.wiki/96674/Change-Threshold-Detection';
  };
  
  const [isReadonly, setIsReadonly] = useState(props.isReadonly);
  // @ts-ignore
  const [series, setSeries] = useState(props.series);
  
  const getInitDetectionType = (props: MeasureSeriesItemProps) => {
    if (props.defaultDetectionType 
      && props?.wholeMetricConfiguration?.smartDetectionCondition === undefined 
      && props?.wholeMetricConfiguration?.hardThresholdCondition === undefined 
      && props?.wholeMetricConfiguration?.changeThresholdCondition === undefined) {
      return props.defaultDetectionType;
    }
    
    if (props?.wholeMetricConfiguration?.changeThresholdCondition !== undefined) {
      return "change";
    }
    
    if (props?.wholeMetricConfiguration?.hardThresholdCondition !== undefined) {
      return "hard";
    }
    
    // all undefined, default use the smart detection as default detection type
    return "smart";
  };
  
  const [detectionType, setDetectionType] = useState(getInitDetectionType(props));
  // @ts-ignore
  const [condidionOperator, setConditionOperator] = useState(props.conditionOperator !== undefined ? props.conditionOperator : ConditionOperatorEnum.AND);

  const DEFAULT_SMART_DETECTION_CONDITION: SmartDetectionConditionEntity = {
    sensitivity: 80,
    anomalyDetectorDirection: AnomalyDetectorDirectionEnum.BOTH,
    suppressCondition: {
      minRatio: 100,
      minNumber: 1,
    },
  };

  const DEFAULT_HARD_THRESHOLD_CONDITION: HardThresholdConditionEntity = {
    lowerBound: 0,
    upperBound: 0, 
    anomalyDetectorDirection: AnomalyDetectorDirectionEnum.BOTH,
    suppressCondition: {
      minRatio: 100,
      minNumber: 1,
    },
  };

  const DEFAULT_CHANGE_THRESHOLD_CONDITION: ChangeThresholdConditionEntity = {
    changePercentage: 5,
    shiftPoint: 1,
    withinRange: false,
    anomalyDetectorDirection: AnomalyDetectorDirectionEnum.BOTH,
    suppressCondition: {
      minRatio: 100,
      minNumber: 1,
    },
  };

  const getInitSmartDetectCondition = (props: MeasureSeriesItemProps, detectionType: "smart" | "hard" | "change") => {
    if (props?.wholeMetricConfiguration?.smartDetectionCondition === undefined && detectionType === "smart") {
      return DEFAULT_SMART_DETECTION_CONDITION;
    } 
    return props?.wholeMetricConfiguration?.smartDetectionCondition;
  };

  const getInitHardThresholdCondition = (props: MeasureSeriesItemProps, detectionType: "smart" | "hard" | "change") => {
    if (props?.wholeMetricConfiguration?.hardThresholdCondition === undefined && detectionType === "hard") {
      return DEFAULT_HARD_THRESHOLD_CONDITION;
    }
    return props?.wholeMetricConfiguration?.hardThresholdCondition;
  };

  const getInitChangeThresholdCondition = (props: MeasureSeriesItemProps, detectionType: "smart" | "hard" | "change") => {
    if (props?.wholeMetricConfiguration?.changeThresholdCondition === undefined && detectionType === "change") {
      return DEFAULT_CHANGE_THRESHOLD_CONDITION;
    }
    return props?.wholeMetricConfiguration?.changeThresholdCondition;
  };
  const [smartDetectionCondition, setSmartDetectionCondition] = useState(getInitSmartDetectCondition(props, detectionType));
  const [hardThresholdCondition, setHardThresholdCondition] = useState(getInitHardThresholdCondition(props, detectionType));
  const [changeThresholdCondition, setChangeThresholdCondition] = useState(getInitChangeThresholdCondition(props, detectionType));
  // @ts-ignore
  const [isExpand, setIsExpand] = useState(props.isExpand);

  const handleDetectionTypeChange = (value:string) => {
    if (value === "smart") {
      setDetectionType(value);
      setSmartDetectionCondition(DEFAULT_SMART_DETECTION_CONDITION);
      setHardThresholdCondition(undefined);
      setChangeThresholdCondition(undefined);  
    } 

    if (value === "hard") {
      setDetectionType(value);
      setSmartDetectionCondition(undefined);
      setHardThresholdCondition(DEFAULT_HARD_THRESHOLD_CONDITION);
      setChangeThresholdCondition(undefined);
    }

    if (value === "change") {
      setDetectionType(value);
      setSmartDetectionCondition(undefined);
      setHardThresholdCondition(undefined);
      setChangeThresholdCondition(DEFAULT_CHANGE_THRESHOLD_CONDITION);
    }
  };

  const handleSmartDetectionChange = (props:SmartDetectionConditionProps, newState: SmartDetectionConditionEntity) => {
    setSmartDetectionCondition(newState);
  };

  const handleHardThresholdChange = (props:HardThresholdConditionProps, newState: HardThresholdConditionEntity) => {
    setHardThresholdCondition(newState);
  };

  const handleChangeThresholdChange = (props:ChangeThresholdConditionProps, newState: ChangeThresholdConditionEntity) => {
    setChangeThresholdCondition(newState);
  };

  const getData = (isExpand: boolean) => {
    const result: MeasureSeriesItemEntity = {
      index: props.index,
      isExpand: isExpand,
      dimensionSeries: props.dimensionSeries,
      wholeMetricConfiguration: {
        conditionOperator: condidionOperator,
        smartDetectionCondition: smartDetectionCondition,
        hardThresholdCondition: hardThresholdCondition,
        changeThresholdCondition: changeThresholdCondition,
      },
      seriesOverrideConfigurations: [],
      dimensionGroupOverrideConfigurations: [],
      configType: "series",
      measure: props.measure,
    };
    console.log(result);
    return result;
  };

  useEffect(() => {
    props.onChange(props.index, props, getData(isExpand));
  }, [series, smartDetectionCondition, hardThresholdCondition, changeThresholdCondition]);

  useEffect(() => {
    setIsReadonly(props.isReadonly);
  }, [props.isReadonly]);
    

  const theme = supersetTheme;
  const rowCss = css`
    display: flex;
    align-items: center;
    margin-top: ${theme.gridUnit * 2}px;
  `;
  const rowGutter:[Gutter, Gutter] = [16, 16];
  const bordered = true;
  const ghost = true;
  const hyperLinkCss = css`
    padding-Left: ${theme.gridUnit * 2}px;
    &:hover {
      text-decoration: underline;
    }
  `;
  return (
    <>
      <CustomCollapse
        isExpand={props.isExpand}
        bordered={bordered}
        ghost={ghost}
        onCollapse={(isExpand: boolean) => {          
          setIsExpand(isExpand);
          props.onChange(props.index, props, getData(isExpand));
        }}
        extra={
          <div>
            <span             
              css={{
                display: 'inline',
                flex: 'auto',
                marginTop: theme.gridUnit,
                color: '#rgb(67 67 67)',
                fontWeight: 500,
                fontSize: '10pt',
            }}>
              {getPivotMeasureLabel(props.measure, props.dimensionSeries)}
            </span>
            {!isReadonly &&
              <InfoTooltipWithTrigger
                icon="times"
                label="remove-item"
                tooltip={t('Remove the measure series')}
                bsStyle="primary"
                onClick={() => props.onRemove(props.index)}
                css={{
                  marginTop: theme.gridUnit * 2,
                  float: 'right',
                }}
              />
            }
          </div>
        }
        // eslint-disable-next-line react/no-children-prop
        children={
          <div>
            <Row css={rowCss} gutter={rowGutter}>
              <Col span={24}>
                <ImportantAsterisk /> DETECTION TYPE
                <InfoTooltipWithTrigger
                  tooltip={getDetectionTypeTooltip()}
                  css={css`
                    margin-left: ${theme.gridUnit * 2}px;
                  `}
                  placement="top"
                />
                <a href={getDetectionDocLink()} 
                  css={hyperLinkCss}
                  target="_blank" 
                  rel="noopener noreferrer">
                  Learn more
                </a>
              </Col>
              <Col span={24}>
                <div
                  css={{
                    display: 'inline-flex',
                    flex: 'auto',
                    marginTop: theme.gridUnit,
                  }}
                >
                  <SelectControl
                    options={DETECTION_TYPE_OPTIONS}
                    holder="Select detection type"
                    value={detectionType}
                    onChange={(value: string) => handleDetectionTypeChange(value)}
                    disabled={isReadonly}
                    clearable={false}
                  />
                  { props?.wholeMetricConfiguration && 
                    getDetectionIntroductionRenderInfoArea()
                    // <Alert
                    //   message={[INFO_MESSAGE_SMART_DETECTION_PAY_ATTENTION].join('')}
                    //   type="info"
                    //   showIcon={false}
                    //   closable={false}
                    //   css={{
                    //     display: 'inline-flex',
                    //     flex: 'auto',
                    //     marginLeft: theme.gridUnit * 2,
                    //   }}
                    // />
                  }
   
                </div>
              </Col>
            </Row>
            <Row css={rowCss} gutter={rowGutter}>
              <Col span={24}>
                <div
                  css={{
                    display: 'inline-flex',
                    flex: 'auto',
                    marginTop: theme.gridUnit,
                  }}
                >
                  {smartDetectionCondition && (
                    <SmartDetectionControl
                      {...smartDetectionCondition}
                      isReadonly={isReadonly}
                      onChange={handleSmartDetectionChange} 
                    />
                  )}
    
                  {hardThresholdCondition && (
                    <HardThresholdControl
                      {...hardThresholdCondition}
                      isReadonly={isReadonly}
                      onChange={handleHardThresholdChange}
                    />
                  )}
    
                  {changeThresholdCondition && (
                    <ChangeThresholdControl
                      {...changeThresholdCondition}
                      isReadonly={isReadonly}
                      onChange={handleChangeThresholdChange}
                    />
                  )}
                </div>
              </Col>
            </Row>
          </div>  
        }
      />
    </>

  );
});


export default withTheme(MeasureSeriesItemControl);