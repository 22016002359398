/**
 * <code>
 * {
 *   "value": "20",
 *   "type": "day",
 *   "message": "The maximum date range is 20 days"
 * }
 * </code>
 * The above JSON configuration will limit the date range to 20 days. If the user tries to select a date range greater than 20 days, an error message will be displayed.
 * This information will be put in dataset's <code>extra</code> property. For example:
 * The extra field JSON content can be
 * <code>
 * {
 *   "data_retention": {
 *     "value": "20",
 *     "type": "day",
 *     "message": "The maximum date range is 20 days"
 *   }
 * }
 * </code>
 *
 * @param startMilliseconds - The start Milliseconds of the query, if it is not set, this value will be 0
 * @param dataRetention - The data_retention field of the dataset.extra, please look at the models.py
 */
export function checkDataRetention(
  startMilliseconds: number,
  dataRetention: any,
) {
  const DURATION_DAY = 24 * 60 * 60 * 1000;
  const NOW = new Date().getTime();
  if (dataRetention) {
    const { value, type, message } = dataRetention;
    if (
      type === 'day' &&
      NOW - startMilliseconds > Number.parseFloat(value) * DURATION_DAY
    ) {
      return message;
    }
  }
  return undefined;
}
