/* eslint-disable react/no-unused-state, prefer-destructuring, dot-notation, prettier/prettier, no-unused-vars */
import {
  css,
  supersetTheme,
  t,
  withTheme,
} from '@superset-ui/core';
import React, { useEffect, useState, memo } from 'react';

import { Col, Row, } from 'src/components';

import SelectControl from 'src/explore/components/controls/SelectControl';

import {
  SmartDetectionConditionProps,
  AnomalyDetectorDirectionEnum,
  SmartDetectionConditionEntity,
  SuppressConditionEntity,
} from 'src/explore/components/controls/SignalAlertsControl/signalAlertsTypes';

import Slider from 'src/components/Slider';
import { InputNumber } from 'src/components/Input';
import { InfoTooltipWithTrigger } from '@superset-ui/chart-controls';

const SENSITIVE_TOOLTIP = t(
  [
    'It is a numerical value to adjust the tolerance of the anomalies. ',
    'Visually the higher the value, the narrower the band (upper/lower bounds) around time series.',
  ].join());

const ANOMALY_TOOLTIP = t(
  [
    'Some metrics are noisy, especially for fine granularity metrics. ',
    'Customers can set \'Do not report anomaly until\' x% of last y points are detected as anomalies. ',
    'A data point is considered as an anomaly only when meeting this criterion.',
  ].join());

const SmartDetectionControl = memo(function SmartDetectionControl(props: SmartDetectionConditionProps) {
  const sliderMin = 0;
  const sliderMax = 100;
  const anomalyDetectorDirectionOptions = [
    {"label": "Out of boundary", "value": AnomalyDetectorDirectionEnum.BOTH, "key": `k-${AnomalyDetectorDirectionEnum.BOTH}`},
    {"label": "Below boundary", "value": AnomalyDetectorDirectionEnum.DOWN, "key": `k-${AnomalyDetectorDirectionEnum.DOWN}`},
    {"label": "Above boundary", "value": AnomalyDetectorDirectionEnum.UP, "key": `k-${AnomalyDetectorDirectionEnum.UP}`},
  ];

  const [sensitivity, setSensitivity] = useState(props.sensitivity ? props.sensitivity : 1);
  const [anomalyDetectorDirection, setAnomalyDetectorDirection] = useState(props.anomalyDetectorDirection);
  const [minRatio, setMinRatio] = useState(props.suppressCondition?.minRatio || 1);
  const [minNumber, setMinNumber] = useState(props.suppressCondition?.minNumber || 1);
  const [isReadonly, setIsReadonly] = useState(props.isReadonly || false);

  const handleSensitivityChange = (value: number) => {
    if (!Number.isNaN(Number(value))) {
      setSensitivity(Number(value));
    }
  }

  const handleAnomalyDetectorDirectionChange = (value: string) => {
    setAnomalyDetectorDirection(value);
  }

  const handleMinRatioChange = (value: number) => {
    if (!Number.isNaN(Number(value))) {
      setMinRatio(Number(value));
    }
  }

  const handleMinNumberChange = (value: number) => {
    if (!Number.isNaN(Number(value))) {
      setMinNumber(Number(value));
    }
  }

  const getData = (): SmartDetectionConditionEntity => {
    const suppressCondition: SuppressConditionEntity = {
      minRatio,
      minNumber: minNumber || 1, // set default value of 1 if minNumber is undefined
    };

    return {
      sensitivity,
      anomalyDetectorDirection,
      suppressCondition,
    };
  };

  useEffect(() => {
    props.onChange(props, getData());
  }, [sensitivity, anomalyDetectorDirection, minRatio, minNumber]);

  useEffect(() => {
    setIsReadonly(props.isReadonly);
  }, [props])

  const theme = supersetTheme;
  const rowCss = css`
    display: flex;
    align-items: center;
    margin-top: ${theme.gridUnit * 2}px;
  `;
  const colorGrey = 'rgb(200, 199, 189)';
  const AUTOTUNING_TOOLTIP = t('System is analyzing the time series and initializing \'auto-tuning\' function.')
  return (
    <div>
      <Row
        css={rowCss}
      >
        <Col span={24}>
          Sensitivity 
          <span 
            css={{color: colorGrey, paddingLeft: '10px'}}>
            Auto Tuning 
            <InfoTooltipWithTrigger
              tooltip={AUTOTUNING_TOOLTIP}
              css={css`
                margin-left: ${theme.gridUnit}px;
                align-self: center;
              `}
              placement="top"
            />
          </span>
        </Col>
        <Col span={12}>
          <Slider
            min={sliderMin}
            max={sliderMax}
            // eslint-disable-next-line react/jsx-no-bind
            value={sensitivity}
            onChange={handleSensitivityChange}
            disabled={isReadonly}
          />
        </Col>
        <Col span={4}>
          <div
            css={{
              display: 'inline-flex',
              flex: 'auto',
              marginTop: theme.gridUnit,
            }}
          >
            <InputNumber
              style={{ margin: '0 16px' }}
              min={sliderMin}
              max={sliderMax}
              value={sensitivity}
              onChange={handleSensitivityChange}
              disabled={isReadonly}
            />
            <InfoTooltipWithTrigger
              tooltip={SENSITIVE_TOOLTIP}
              css={css`
                margin-left: ${theme.gridUnit}px;
                align-self: center;
              `}
              placement="top"
            />
          </div>
        </Col>
      </Row>      

      <Row
        css={rowCss}
      >
        <Col span={3}>
          <div
            css={{
              display: 'inline-flex',
              flex: 'auto',
              marginTop: theme.gridUnit,
            }}
          >
            {t('Value')}
          </div>
        </Col>
        <Col span={11}>
          <SelectControl
            css={{               
              marginRight: theme.gridUnit * 2,
              marginLeft: theme.gridUnit * 2,
              marginTop: theme.gridUnit, 
            }}
            options={anomalyDetectorDirectionOptions}
            value={anomalyDetectorDirection}
            onChange={handleAnomalyDetectorDirectionChange}
            clearable={false}
            disabled={isReadonly}
          />
        </Col>
        <Col span={10}>
          <div
            css={{
              flex: 'auto',
              marginTop: theme.gridUnit,
              marginLeft: theme.gridUnit * 4,
            }}
          >
            {t('is an anomaly')}
          </div>
        </Col>
      </Row>
      
      <Row
        css={rowCss}
      >
        <Col>
          <div
            css={{
              display: 'inline-flex',
              flex: 'auto',
              marginTop: theme.gridUnit,
            }}
          >
            Do not report anomaly until
          </div>
          <div
            css={{
              flex: 'auto',
              display: 'inline-flex',
              marginRight: theme.gridUnit * 2,
              marginLeft: theme.gridUnit * 2,
              marginTop: theme.gridUnit,
            }}
          >
            <InputNumber
              min={0}
              max={100}
              value={minRatio}
              onChange={handleMinRatioChange}
              disabled={isReadonly}
            />
          </div>
          <div
            css={{
              display: 'inline-flex',
              flex: 'auto',
              marginTop: theme.gridUnit,
            }}
          >
            % of latest
          </div>
          <div
            css={{
              flex: 'auto',
              display: 'inline-flex',
              marginRight: theme.gridUnit * 2,
              marginLeft: theme.gridUnit * 2,
              marginTop: theme.gridUnit,
            }}
          >
            <InputNumber
              min={1}
              value={minNumber}
              onChange={handleMinNumberChange}
              disabled={isReadonly}
              formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              parser={(value) => value!.replace(/,*/g, '')}
            />
          </div>
          <div
            css={{
              display: 'inline-flex',
              flex: 'auto',
              marginTop: theme.gridUnit,
            }}
          >
            points are detected as anomalies.
            <InfoTooltipWithTrigger
              tooltip={ANOMALY_TOOLTIP}
              css={css`
                margin-left: ${theme.gridUnit}px;
                align-self: center;
              `}
              placement="top"
            />
          </div>
        </Col>
      </Row>
    </div>
  );
});

export default withTheme(SmartDetectionControl);