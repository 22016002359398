import {t} from '@superset-ui/core';

/**
 * @fileoverview Signal Alerts Message Config
 */

export const WARNING_MESSAGE_SETTING_CHANING = t([
  'Be careful. ',
  'Chart has been changed. ',
  'Please review your signal based on latest data.',
  'Recreate if needed.'
].join(''));
export const WARNING_MESSAGE_FORM_DATA_CHANGED = WARNING_MESSAGE_SETTING_CHANING
export const INFO_MESSAGE_QUERY_DATA_CHANGED = t([
  'Be careful. ',
  'Chart query data has been changed. ',
  'Please review your signal based on latest data. '
].join(''));

// const ERROR_MESSAGE_SERIES_LIMIT_IS_REQUIRED = t([
//   'Be careful. ',
//   'Series limit is required, ',
//   'only 1 to 5 series each measure are allowed. ',
// ].join(''));

export const ERROR_MESSAGE_GRANULARITY_NOT_VALID = t([
  'Be careful. ',
  'Granularity is not valid, ',
  'only support day and week. ',
].join(''));

export const INFO_MESSAGE_SMART_DETECTION_PAY_ATTENTION = t([
  'Smart Detection will only start reporting anomaly after accumulating 28 days of data. ',
].join(''));

export const INFO_MESSAGE_CHANGE_THRESHOLD_DETECTION_PAY_ATTENTION = t([
  'Hard Threshold and Change Threshold will take effect 24 hours after setting the signal.'
].join(''));

export const INFO_MESSAGE_HARD_THRESHOLD_DETECTION_PAY_ATTENTION = INFO_MESSAGE_CHANGE_THRESHOLD_DETECTION_PAY_ATTENTION

export const INFO_MESSAGE_ALL_DETECTION_PAY_ATTENTION = t([
  'Please note that ',
  INFO_MESSAGE_SMART_DETECTION_PAY_ATTENTION,
  INFO_MESSAGE_CHANGE_THRESHOLD_DETECTION_PAY_ATTENTION, 
].join(''));