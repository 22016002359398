import { SupersetClient, DatasourceType, JsonObject } from '@superset-ui/core';

export type ReturnEarlyFunction = () => boolean;
export type BeforeStateCheck = (json: JsonObject) => void;
export type StateServiceErrorHandler = (state: string) => void;
export type StateServiceNotReadyHandler = (state: string) => void;
export type StateColumnEnabledHandler = (
  state: string,
  json: JsonObject,
) => void;
export type StateColumnDisabledHandler = (state: string) => void;
export type StateTableDisabledHandler = (state: string) => void;
export type StateOtherDefaultHandler = (state: string) => void;
export type CacheExistCheckExceptionHandler = (e: any) => void;

export const cacheExistCheck = (
  datasoruceType: DatasourceType,
  datasourceId: number,
  unwrappedCol: string,
  returnEarlyFn: ReturnEarlyFunction,
  beforeStateCheckFn: BeforeStateCheck,
  getAutocompleteCacheStateHandler: (json: JsonObject) => string,
  stateServiceErrorHandler: StateServiceErrorHandler,
  stateServiceNotReadyHandler: StateServiceNotReadyHandler,
  stateColumnEnabledHandler: StateColumnEnabledHandler,
  stateColumnDisabledHandler: StateColumnDisabledHandler,
  stateTableDisabledHandler: StateTableDisabledHandler,
  stateOtherDefaultHandler: StateOtherDefaultHandler,
  exceptionHandler: CacheExistCheckExceptionHandler,
) => {
  SupersetClient.get({
    endpoint: `/nezha/filter/${datasoruceType}/${datasourceId}/${unwrappedCol}/cache/check/`,
  })
    .then(({ json }) => {
      if (returnEarlyFn && returnEarlyFn()) {
        // for fetch callback order
        return;
      }

      const state = getAutocompleteCacheStateHandler(json);

      if (beforeStateCheckFn) {
        beforeStateCheckFn(json);
      }

      switch (state) {
        case 'cache.autocomplete.service.error':
          if (stateServiceErrorHandler) {
            stateServiceErrorHandler(state);
          }
          break;
        case 'cache.autocomplete.service.not_ready':
          if (stateServiceNotReadyHandler) {
            stateServiceNotReadyHandler(state);
          }
          break;
        case 'cache.autocomplete.column.enabled':
          if (stateColumnEnabledHandler) {
            stateColumnEnabledHandler(state, json);
          }
          break;
        case 'cache.autocomplete.column.disabled':
          if (stateColumnDisabledHandler) {
            stateColumnDisabledHandler(state);
          }
          break;
        case 'cache.autocomplete.table.disabled':
          if (stateTableDisabledHandler) {
            stateTableDisabledHandler(state);
          }
          break;
        default:
          if (stateOtherDefaultHandler) {
            stateOtherDefaultHandler(state);
          }
          break;
      }
    })
    .catch(e => {
      if (exceptionHandler) {
        exceptionHandler(e);
      }
    });
};

export type CacheIncrbyScoreExceptionHandler = (
  e: any,
  apiPath: string,
) => void;

export const cacheIncrbyScore = (
  datasoruceType: DatasourceType,
  datasourceId: number,
  unwrappedCol: string,
  items: string[],
  afterIncrbyScore: (json: JsonObject, apiPath: string) => void,
  exceptionHandler: CacheIncrbyScoreExceptionHandler,
) => {
  const contextPath = `/nezha/filter/${datasoruceType}/${datasourceId}/${unwrappedCol}/cache/incrby/`;
  SupersetClient.post({
    endpoint: contextPath,
    jsonPayload: { items },
  })
    .then(({ json }) => {
      // deal with the result or not do anything
      if (afterIncrbyScore) {
        afterIncrbyScore(json, contextPath);
      }
    })
    .catch(e => {
      if (exceptionHandler) {
        exceptionHandler(e, contextPath);
      }
    });
};
