import { t } from '../translation';

export default function computeTimeRangeErrorMessage(
  timeGran: string,
  timeActualRange: string,
) {
  // console.log('computeTimeRangeErrorMessage, ', timeGran, timeActualRange);
  let errorMessage = '';
  if (timeGran && timeGran !== 'undefined') {
    const timePair: string[] = timeActualRange?.split(' ≤ col < ') || [];
    if (timePair.length > 1) {
      const DURATION_DAY = 24 * 60 * 60 * 1000;
      const DURATION_WEEK = 7 * DURATION_DAY;
      const DURATION_MONTH = 30 * DURATION_DAY;
      const DURATION_QUARTER = 3 * DURATION_MONTH;
      const DURATION_YEAR = 3 * DURATION_QUARTER + 5;
      const delta =
        new Date(timePair[1]).getTime() - new Date(timePair[0]).getTime();
      if (delta > DURATION_DAY && delta <= DURATION_WEEK) {
        // When time range is more than one day, user can't select "Minute".
        if (timeGran === 'PT1M') {
          errorMessage = t(
            'Time grain "Minute" is not allowed for this time range. ',
          );
        }
      } else if (delta > DURATION_WEEK && delta <= DURATION_YEAR) {
        const foundval = ['PT1M', 'PT5M', 'PT10M', 'PT15M', 'PT30M'].find(
          s => s === timeGran,
        );
        if (foundval) {
          // When time range is more than one week, user can't select minute, 5/10/15/30 minute.
          errorMessage = t(
            'Time grain "Minute", "5 minute", "10 minute", "15 minute", "30 minute" are not allowed for this time range.',
          );
        }
      } else if (delta > DURATION_YEAR) {
        const foundval = [
          'PT1M',
          'PT5M',
          'PT10M',
          'PT15M',
          'PT30M',
          'PT1H',
          'PT6H',
        ].find(s => s === timeGran);
        if (foundval) {
          // When time range is more than one year, user can't select minute, 5/10/15/30 minute, 1/6 hour and 1 day.
          errorMessage = t(
            'Time grain "Minute", "5 minute", "10 minute", "15 minute", "30 minute", "1 hour", "6 hour" are not allowed for this time range.',
          );
        }
      }
    }
  }
  // console.log(errorMessage);
  if (errorMessage !== '') {
    return t(errorMessage);
  }
  return '';
}
