/* eslint-disable react/no-unused-state, prefer-destructuring, dot-notation, prettier/prettier, object-shorthand, no-unused-vars */
import {
  css,
  supersetTheme,
  // @ts-ignore
  t,
  withTheme,
} from '@superset-ui/core';
import React, { useEffect, useState, memo } from 'react';
import { Form } from 'antd';
import { Col, Row } from 'src/components';
import { Gutter } from 'antd/lib/grid/row';
import { TextArea  } from 'src/components/Input';
import {
  DetailSectionEntity,
  DetailSectionProps,
} from './signalAlertsTypes';


const DetailSectionControl = memo(function DetailSectionControl(props: DetailSectionProps) {
  const [description, setDescription] = useState(props.description);
  // const [ingestionStartTime, setIngestionStartTime] = useState(props.ingestionStartTime || new Date().toISOString().slice(0, 10));
  const [isReadonly, setIsReadonly] = useState(props.isReadonly);
  // @ts-ignore
  const [createdBy, setCreatedBy] = useState(props.createdBy);
  
  const handleSignalNameChange = (value:string) => {
    setDescription(value);
  }

  const getData = () => {
    const result: DetailSectionEntity = {
      description: description,
      ingestionStartTime: props.ingestionStartTime,
    };
    return result;
  };

  useEffect(() => {
    props.onChange(props, getData());
  }, [description]);

  useEffect(() => {
    setIsReadonly(props.isReadonly);
  }, [props.isReadonly]);

  const theme = supersetTheme;
  const rowCss = css`
    display: flex;
    align-items: center;
    margin-top: ${theme.gridUnit * 2}px;
  `;
  const rowGutter:[Gutter, Gutter] = [16, 16]
  return (
    <div>
      <Row css={rowCss} gutter={rowGutter}  
      >
        <Col span={24}>
          <div
            css={{
              paddingTop: theme.gridUnit,
            }}
          >
              <Form.Item
                label={
                  <>
                    <span>DESCRIPTION</span>
                  </>
                }
                name="description"
                rules={[
                  {
                    required: true,
                    message: t('Description is required'),
                  },
                ]}
                initialValue={(description && description.length > 0) ? description : undefined}
              >
                <TextArea 
                  placeholder="Description"
                  value={description}
                  onChange={(e) => handleSignalNameChange(e.target.value)}
                  disabled={isReadonly}
                  style={{ height: 50, resize: 'vertical' }}
                />
              </Form.Item>
            </div>
        </Col>
      </Row>
      {/* {props.createdBy &&
        <Row css={rowCss} gutter={rowGutter}>
          <Col span={24}>
            <div>CREATED BY</div>
            <div>
              {createdBy}
            </div>
          </Col>
        </Row>
      } */}
    </div>
  );
}); 

export default withTheme(DetailSectionControl);