/**
 * Licensed to the Apache Software Foundation (ASF) under one
 * or more contributor license agreements.  See the NOTICE file
 * distributed with this work for additional information
 * regarding copyright ownership.  The ASF licenses this file
 * to you under the Apache License, Version 2.0 (the
 * "License"); you may not use this file except in compliance
 * with the License.  You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 */
import React, { useState, ReactNode, useLayoutEffect } from 'react';
import { css, GenericDataType, styled, supersetTheme, SupersetTheme } from '@superset-ui/core';
import { ExportOutlined } from '@ant-design/icons';
import { Tooltip } from './Tooltip';
import { ColumnTypeLabel } from './ColumnTypeLabel/ColumnTypeLabel';
import CertifiedIconWithTooltip from './CertifiedIconWithTooltip';
import { ColumnMeta } from '../types';
import {
  getColumnLabelText,
  getColumnTooltipNode,
  getColumnTypeTooltipNode,
} from './labelUtils';
import { SQLPopover } from './SQLPopover';

export type ColumnOptionProps = {
  column: ColumnMeta;
  showType?: boolean;
  labelRef?: React.RefObject<any>;
  activeTab?: string;
};

const StyleOverrides = styled.span`
  display: flex;
  align-items: center;
  svg {
    margin-right: ${({ theme }) => theme.gridUnit}px;
  }
`;

const StyledEyeOutlined = styled(ExportOutlined)`
  ${({ theme }) => css`
    color: ${theme.colors.grayscale.base};
    font-size: ${theme.typography.sizes.s}px;
    & svg {
      margin-left: ${theme.gridUnit}px;
      margin-right: ${theme.gridUnit}px;
    }
  `}
`;

export function ColumnOption({
  column,
  labelRef,
  showType = false,
  activeTab,
}: ColumnOptionProps) {
  const { expression, column_name } = column;
  let { type_generic } = column;
  if (column.type === 'NULLABLE(INT32)') type_generic = GenericDataType.NUMERIC
  const hasExpression = expression && expression !== column_name;
  const type = hasExpression ? 'expression' : type_generic;
  const [tooltipText, setTooltipText] = useState<ReactNode>(column.column_name);
  const [columnTypeTooltipText, setcolumnTypeTooltipText] = useState<ReactNode>(
    column.type,
  );
  const theme = supersetTheme;
  const name_title = (
    <span style={{ color: theme.colors.grayscale.dark1 }}>{tooltipText}</span>
  );
  const type_title = (
    <span style={{ color: theme.colors.grayscale.dark1 }}>{columnTypeTooltipText}</span>
  );

  useLayoutEffect(() => {
    setTooltipText(getColumnTooltipNode(column, labelRef, activeTab));
    setcolumnTypeTooltipText(getColumnTypeTooltipNode(column));
  }, [labelRef, column]);

  return (
    <StyleOverrides>
      {showType && type !== undefined && (
        <Tooltip
          id="metric-type-tooltip"
          title={type_title}
          placement="bottomRight"
          align={{ offset: [8, -2] }}
          color={theme.colors.grayscale.light4}
        >
          <span>
            <ColumnTypeLabel type={type} />
          </span>
        </Tooltip>
      )}
      <Tooltip id="metric-name-tooltip" title={name_title} placement="rightBottom" color={theme.colors.grayscale.light4}>
        <span
          className="option-label column-option-label"
          css={(theme: SupersetTheme) => css`
            margin-right: ${theme.gridUnit}px;
          `}
          ref={labelRef}
        >
          {getColumnLabelText(column)}
        </span>
      </Tooltip>
      {hasExpression && <SQLPopover sqlExpression={expression} />}
      {column.is_certified && (
        <CertifiedIconWithTooltip
          metricName={column.metric_name}
          certifiedBy={column.certified_by}
          details={column.certification_details}
        />
      )}
      {activeTab === 'COHORT' && <a
        target="_blank"
        rel="noreferrer"
        href={`/explore/?cohort_id=${column.id}`}
      >
        <StyledEyeOutlined />
      </a>}
    </StyleOverrides>
  );
}

export default ColumnOption;
