/* eslint-disable react/no-unused-state, prefer-destructuring, dot-notation, prettier/prettier */
import { DownOutlined } from '@ant-design/icons';
import {
  css,
} from '@superset-ui/core';
import React, { useEffect } from 'react';
import Collapse from 'src/components/Collapse';

export type CustomCollapseType = {
  children: React.ReactNode;
  extra: React.ReactNode;
  isExpand: boolean;
  bordered: boolean;
  ghost: boolean;
  onCollapse?: (isExpand: boolean) => void;
};

interface TriggerExpandFormat {
  isExpand: boolean;
};

export const CustomCollapse = (props: CustomCollapseType) => {
  const [activeKey, setActiveKey] = React.useState<string[]>([]);
  const { children, extra } = props;
  const [triggerExpand, setTriggerExpand] = React.useState<TriggerExpandFormat>({ isExpand: props.isExpand });
  const customHeader = (
    <DownOutlined
      onClick={() => {
        setActiveKey(activeKey.length === 0 ? ['0'] : []);
      }}
      style={{ paddingTop: '8px', paddingRight: '8px' }}
      rotate={activeKey.length === 0 ? 0 : 180}
    />
  );

  const onChange = (...info:any) => {
    console.log('onChange', info);
    const key = info as string[] | string;
    const expand = !(key === undefined || key.length === 0)
    setTriggerExpand({ isExpand: expand });
  };

  useEffect(() => {
    if (props.onCollapse) {
      props.onCollapse(triggerExpand.isExpand);
    }
  }, [triggerExpand]);
  

  useEffect(() => {
    setActiveKey(props.isExpand === true ? ['0'] : []);
  }, [props.isExpand]);

  return (
    <Collapse
      ghost={props.ghost === undefined ? true : props.ghost}
      bordered={props.bordered === undefined ? true : props.bordered}
      css={css`
        .ant-collapse-item {
          .ant-collapse-header {
            border-bottom: 0px;
            padding: 0px !important;
            display: inline-block;
            width: 100%;
          }
        }
        .ant-collapse-extra {
          width: calc(100% - 24px);
        }
        width: 100%;
      `}
      activeKey={activeKey}
      onChange={onChange}
    >
      <Collapse.Panel
        showArrow={false} // hide the default arrow so that we can use the custom one
        key="0"
        header={customHeader}
        extra={extra}
        css={css`
          .ant-collapse-header {
            padding: 0px;
          }
        `}
      >
        {children}
      </Collapse.Panel>
    </Collapse>
  );
};
