import { ColumnMeta } from '@superset-ui/chart-controls';
import { SupersetTheme } from '@superset-ui/core';
import PropTypes from 'prop-types';
import AdhocFilterControl from 'src/explore/components/controls/FilterControl/AdhocFilterControl';
import SelectControl from 'src/explore/components/controls/SelectControl';

export enum MultiMetricValueTypes {
  MeasureName = 'MeasureName',
  SelectControlPercentile = 'SelectControlPercentile',
  SelectControlAggregator = 'SelectControlAggregator',
  SelectControlColumn = 'SelectControlColumn',
  AdhocFilterControl = 'AdhocFilterControl',
  HasChanged = 'hasChanged',
  AddMeasure = 'AddMeasure',
  AddSelection = 'AddSelection',
  AddCalculation = 'AddCalculation',
  measure_id = 'measure_id',
  tags = 'tags',
  SelectMeasure = 'SelectMeasure',
  SelectOprator = 'SelectOprator',
  RemoveSelection = 'RemoveSelection',
  SavedMeasure = 'SavedMeasure',
  CalculatedMeasureValue = 'CalculatedMeasureValue',
}

// Calculated measure have the follwoing 5 types
export enum CalValueTypes {
  Null = 'Null',
  Number = 'Number',
  SavedMeasure = 'SavedMeasure',
  ChartMeasure = 'ChartMeasure',
  Operator = 'Operator',
}

export type MultiMetricValue = {
  calculatedMeasureLogic?: string;
  tags?: string[];
  key?: number;
  [MultiMetricValueTypes.SelectControlPercentile]?: string;
  [MultiMetricValueTypes.MeasureName]?: string;
  [MultiMetricValueTypes.SelectControlAggregator]?: string;
  [MultiMetricValueTypes.SelectControlColumn]?: string;
  [MultiMetricValueTypes.AdhocFilterControl]?: any;
  isValid?: boolean;
  isExpand?: boolean;
  hasChanged?: boolean;
  hasInitial?: boolean;
  metric?: any;
  measure_id?: number;
  ValidName?: boolean;
  isCalculatedMeasure?: boolean;
  calculatedMeasures?: any;
  measure_ids?: number[];
  isValidExpression?: boolean;
  calMeasureVersion?: number;
  sqlExpression?: string;
  validationErrors?: string;
};

export type MultiMetricProps = {
  label?: string;
  description?: string;
  placeholder?: string;
  addTooltip?: string;
  nameControlConfig: PropTypes.InferProps<typeof SelectControl.propTypes>;
  selectControlPercentileConfig: PropTypes.InferProps<
    typeof SelectControl.propTypes
  >;
  selectControlAggregatorConfig: PropTypes.InferProps<
    typeof SelectControl.propTypes
  >;
  selectControlColumnConfig: PropTypes.InferProps<
    typeof SelectControl.propTypes
  >;
  adhocFilterControlConfig: PropTypes.InferProps<
    typeof AdhocFilterControl.propTypes
  >;
  onChange: (o: MultiMetricValue[]) => void;
  value: MultiMetricValue[];
  columns: ColumnMeta[];
  theme?: SupersetTheme;
  datasource?: any;
  sections?: string[];
  selectedMetrics: object;
  form_data?: any;
  controls?: any;
  multi?: boolean;
  actions: Record<string, any>;
  saveDisabled?: boolean;
  filterOptions?: any;
  viz_type?: string;
};

export type MultiMetricState = {
  savedMeasures?: any;
  multi?: boolean;
  selectControlAggregatorConfig?: PropTypes.InferProps<
    typeof SelectControl.propTypes
  >;
};
