/* eslint-disable react/no-unused-state, prefer-destructuring, dot-notation, prettier/prettier, no-unused-vars */
import {
    css,
    supersetTheme,
    t,
    withTheme,
} from '@superset-ui/core';
import SelectControl from 'src/explore/components/controls/SelectControl';
import React, { useEffect, useState, memo } from 'react';
import { Col, Row, } from 'src/components';
import {
  HardThresholdConditionProps,
  AnomalyDetectorDirectionEnum,
  HardThresholdConditionEntity,
  SuppressConditionEntity,
} from 'src/explore/components/controls/SignalAlertsControl/signalAlertsTypes';
import { InputNumber } from 'src/components/Input';
import { InfoTooltipWithTrigger } from '@superset-ui/chart-controls';

const ANOMALY_TOOLTIP = t(
  [
    'Some metrics are noisy, especially for fine granularity metrics. ',
    'Customers can set \'Do not report anomaly until\' x% of last y points are detected as anomalies. ',
    'A data point is considered as an anomaly only when meeting this criterion.',
  ].join());


const HardThresholdControl = memo(function HartThresholdControl(props: HardThresholdConditionProps) {
  const [lowerBound, setLowerBound] = useState(props.lowerBound ? props.lowerBound : 0);
  const [upperBound, setUpperBound] = useState(props.upperBound ? props.upperBound : 0);
  const [anomalyDetectorDirection, setAnomalyDetectorDirection] = useState(props.anomalyDetectorDirection);
  const [minRatio, setMinRatio] = useState(props.suppressCondition?.minRatio || 100);
  const [minNumber, setMinNumber] = useState(props.suppressCondition?.minNumber || 1);  
  const [isReadonly, setIsReadonly] = useState(props.isReadonly || false);

  const handleLowerBoundChange = (value: number) => {
    if (Number.isNaN(Number(value))) {
      return;
    }

    if (anomalyDetectorDirection === AnomalyDetectorDirectionEnum.DOWN) {
      setUpperBound(Number(value) + 1);
    } else if (Number(value) > upperBound) {
      setUpperBound(Number(value) + 1);
    }
    setLowerBound(Number(value));
    
  }
  const handleUpperBoundChange = (value: number | string | undefined) => {
    if (Number.isNaN(Number(value))) {
      return;
    }

    if (anomalyDetectorDirection === AnomalyDetectorDirectionEnum.UP) {
      setLowerBound(Number(value) - 1);
    } else if (Number(value) < lowerBound) {
      setLowerBound(Number(value) - 1);
    }
    setUpperBound(Number(value));
  }
  const handleAnomalyDetectorDirectionChange = (value: string) => {
    if (anomalyDetectorDirection === AnomalyDetectorDirectionEnum.DOWN) {
      setUpperBound(lowerBound + 1);
    } else if (anomalyDetectorDirection === AnomalyDetectorDirectionEnum.UP) {
      setLowerBound(upperBound - 1);
    }
    setAnomalyDetectorDirection(value);
  }
  const handleMinRatioChange = (value: number | string | undefined) => {
    if (Number.isNaN(Number(value))) {
      return;
    }
    setMinRatio(Number(value));
  }
  const handleMinNumberChange = (value: number | string | undefined) => {
    if (Number.isNaN(Number(value))) {
      return;
    }
    setMinNumber(Number(value));
  }

  const getData = (): HardThresholdConditionEntity => {
    const suppressCondition: SuppressConditionEntity = {
      minRatio,
      minNumber: minNumber || 1, // set default value of 1 if minNumber is undefined
    };

    return {
      lowerBound,
      upperBound,
      anomalyDetectorDirection,
      suppressCondition,
    };
  };

  useEffect(() => {
    props.onChange(props, getData());
  }, [lowerBound, upperBound, anomalyDetectorDirection, minRatio, minNumber]);

  useEffect(() => {
    setIsReadonly(props.isReadonly);
  }, [props])

  const theme = supersetTheme;
  const rowCss = css`
    display: flex;
    align-items: center;
    margin-top: ${theme.gridUnit * 2}px;
  `;
  return (
    <div>
      <Row
        css={rowCss}
      >
        <Col span={3}>
          <div
            css={{
              display: 'inline-flex',
              flex: 'auto',
              marginTop: theme.gridUnit,
            }}
          >
            {t('Value')}
          </div>
        </Col>
        <Col span={21}>
          <SelectControl
            css={{
              width: '100px', 
              maxWidth: '100px',
              minWidth: '100px',
              marginRight: theme.gridUnit * 2,
              marginLeft: theme.gridUnit * 2,
              marginTop: theme.gridUnit, 
            }}
            options={[
              {"label": "Out of range", "value": AnomalyDetectorDirectionEnum.BOTH, "key": `k-${AnomalyDetectorDirectionEnum.BOTH}`},
              {"label": "Below", "value": AnomalyDetectorDirectionEnum.DOWN, "key": `k-${AnomalyDetectorDirectionEnum.DOWN}`},
              {"label": "Above", "value": AnomalyDetectorDirectionEnum.UP, "key": `k-${AnomalyDetectorDirectionEnum.UP}`},
            ]}
            defaultValue={AnomalyDetectorDirectionEnum.BOTH}
            value={anomalyDetectorDirection}
            onChange={handleAnomalyDetectorDirectionChange}
            clearable={false}
            disabled={isReadonly}
          />
        </Col>
      </Row>
      <Row
        css={rowCss}
      >
        <Col span={24}>
          <div
            css={{
              display: anomalyDetectorDirection === AnomalyDetectorDirectionEnum.UP ? 'none' : 'inline-flex',
              flex: 'auto',
              marginTop: theme.gridUnit,
            }}
          >
            {t('Min')}
          </div>
          <div
            css={{
              flex: 'auto',
              display: anomalyDetectorDirection === AnomalyDetectorDirectionEnum.UP ? 'none' : 'inline-flex',
              marginRight: theme.gridUnit * 2,
              marginLeft: theme.gridUnit * 2,
              marginTop: theme.gridUnit,
            }}
          >
            <InputNumber
              css={{width: '150px',}}
              value={lowerBound}
              onChange={handleLowerBoundChange}
              disabled={isReadonly}
              formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              parser={(value) => value!.replace(/,*/g, '')}
            />
          </div>
          <div
            css={{
              display: anomalyDetectorDirection === AnomalyDetectorDirectionEnum.DOWN ? 'none' : 'inline-flex',
              flex: 'auto',
              marginTop: theme.gridUnit,
            }}
          >
            {t('Max')}
          </div>
          <div
            css={{
              flex: 'auto',
              display: anomalyDetectorDirection === AnomalyDetectorDirectionEnum.DOWN ? 'none' : 'inline-flex',
              marginRight: theme.gridUnit * 2,
              marginLeft: theme.gridUnit * 2,
              marginTop: theme.gridUnit,
            }}
          >
            <InputNumber
              css={{width: '150px',}}
              value={upperBound}
              onChange={handleUpperBoundChange}
              disabled={isReadonly}
              formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              parser={(value) => value!.replace(/,*/g, '')}
            />
          </div>
          <div
            css={{
              display: 'inline-flex',
              flex: 'auto',
              marginTop: theme.gridUnit,
            }}
          >
            {t('is an anomaly.')}
          </div>
        </Col>
      </Row>
      <Row
        css={rowCss}
      >
        <Col span={24}>
          <div
            css={{
              display: 'inline-flex',
              flex: 'auto',
              marginTop: theme.gridUnit,
            }}
          >
            {t('Do not report anomaly until')}
          </div>
          <div
            css={{
              flex: 'auto',
              display: 'inline-flex',
              marginRight: theme.gridUnit * 2,
              marginLeft: theme.gridUnit * 2,
              marginTop: theme.gridUnit,
            }}
          >
            <InputNumber
              min={0}
              max={100}
              value={minRatio}
              onChange={handleMinRatioChange}
              disabled={isReadonly}
            />
          </div>
          <div
            css={{
              display: 'inline-flex',
              flex: 'auto',
              marginTop: theme.gridUnit,
            }}
          >
            {t('% of latest')}
          </div>
          <div
            css={{
              flex: 'auto',
              display: 'inline-flex',
              marginRight: theme.gridUnit * 2,
              marginLeft: theme.gridUnit * 2,
              marginTop: theme.gridUnit,
            }}
          >
            <InputNumber
              min={1}
              value={minNumber}
              onChange={handleMinNumberChange}
              disabled={isReadonly}
              formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              parser={(value) => value!.replace(/,*/g, '')}
            />
          </div>
          <div
            css={{
              display: 'inline-flex',
              flex: 'auto',
              marginTop: theme.gridUnit,
            }}
          >
            {t('points are detected as anomalies.')}
            <InfoTooltipWithTrigger
              tooltip={ANOMALY_TOOLTIP}
              css={css`
                margin-left: ${theme.gridUnit}px;
                align-self: center;
              `}
              placement="top"
            />
          </div>
        </Col>
      </Row>
    </div> 
  );
});

export default withTheme(HardThresholdControl);