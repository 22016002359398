/* eslint-disable react/no-unused-state, prefer-destructuring, dot-notation, prettier/prettier, object-shorthand, arrow-body-style, no-unused-vars */
import {
  css,
  t,
  supersetTheme,
  withTheme,
} from '@superset-ui/core';
import React, { useEffect, useState, memo } from 'react';
import { Col, Row } from 'src/components';
import SelectControl from 'src/explore/components/controls/SelectControl';
import { SignalStatus } from 'src/types/Signal';

import { Gutter } from 'antd/lib/grid/row';
import { Switch } from 'src/components/Switch';
import { Form } from 'antd';
import { InfoTooltipWithTrigger } from '@superset-ui/chart-controls';
import {
  ScheduleSectionEntity,
  ScheduleSectionProps,
  ScheduleDelaySettingProps,
  ScheduleDelaySettingEntity,
  InValue,
  TimeGranularity,
} from './signalAlertsTypes';
import ScheduleDelaySettingControl from './ScheduleDelaySettingControl';


const SIGNAL_GRANULARITY_OPTIONS = Object.keys(TimeGranularity).map((key) => {
  return { label: key, value: TimeGranularity[key] };
});


const ScheduleSectionControl = memo(function ScheduleSectionControl(props: ScheduleSectionProps) {
  const [status, setStatus] = useState<number>(props.status);
  // @ts-ignore
  const [granularity, setGranularity] = useState(props.granularity);

  // @ts-ignore
  const [delay, setDelay] = useState(props.delay);

  const getValidSignalGranularityOptions = (granularity: string): InValue[] => {
    const validOptions = {
      // [TimeGranularity.HOUR]: [TimeGranularity.HOUR, TimeGranularity.DAY, TimeGranularity.WEEK],
      [TimeGranularity.DAY]: [TimeGranularity.DAY, TimeGranularity.WEEK],
      [TimeGranularity.WEEK]: [TimeGranularity.WEEK],
    };
    return SIGNAL_GRANULARITY_OPTIONS.filter((option) => validOptions[granularity]?.includes(option.value));
  };
  const [validSignalGranularityOptions, setValidSignalGranularityOptions] = useState<InValue[]>(getValidSignalGranularityOptions(granularity));

  const getInitSignalGranularity = (validSignalGranularityOptions?: InValue[], signalGranularity?: string) => {
    if (validSignalGranularityOptions && validSignalGranularityOptions.some((option) => option.value === signalGranularity)) {
      return signalGranularity;
    }
    if (validSignalGranularityOptions && validSignalGranularityOptions.length > 0) {
      return validSignalGranularityOptions[0].value;
    }
    return undefined;
  }

  const [signalGranularity, setSignalGranularity] = useState(getInitSignalGranularity(validSignalGranularityOptions, props.signalGranularity));
  const [isReadonly, setIsReadonly] = useState(props.isReadonly);

  const handleEnableCheckboxChange = (val?: boolean) => {
    setStatus(val ? SignalStatus.ACTIVE : SignalStatus.DISABLED);
    console.log("handleEnableCheckboxChange", val, status)
  };

  const handleSignalGranularityChange = (value: string) => {
    setSignalGranularity(value);
  }

  const getData = (): ScheduleSectionEntity => {
    const result: ScheduleSectionEntity = {
      status: status,
      granularity: granularity,
      signalGranularity: signalGranularity,
      delay: delay,
    };
    return result;
  };

  useEffect(() => {
    props.onChange(props, getData());
  }, [status, signalGranularity, delay]);

  useEffect(() => {
    setIsReadonly(props.isReadonly);
  }, [props.isReadonly]);

  useEffect(() => {
    setValidSignalGranularityOptions(getValidSignalGranularityOptions(granularity));
    setSignalGranularity(getInitSignalGranularity(getValidSignalGranularityOptions(granularity), signalGranularity));
  }, [props.granularity]);

  const theme = supersetTheme;
  const rowCss = css`
    display: flex;
    align-items: center;
    margin-top: ${theme.gridUnit * 2}px;
  `;
  const rowCssDisplayNone = css`
    display: none;
  `;
  const rowGutter: [Gutter, Gutter] = [16, 16]
  return (
    <div>
      <Row css={rowCssDisplayNone} gutter={rowGutter}>
        <Col span={24}>
          <div css={{
            marginTop: theme.gridUnit * 2,
          }}>
            Enable
            {props.isReadonly ?
              <span
                css={{
                  paddingLeft: theme.gridUnit * 2,
                }}
              >
                {status === SignalStatus.ACTIVE ? "ON" : "OFF"}
              </span>
              :
              <span
                css={{
                  paddingLeft: theme.gridUnit * 2,
                }}
              >
                <Switch
                  checked={status === SignalStatus.ACTIVE}
                  onChange={(val) => handleEnableCheckboxChange(val)}
                />
              </span>
            }
          </div>
        </Col>
      </Row>

      <Row
        gutter={[16, 16]}
        css={rowCss}
      >
        <Col span={24}>
          <div>
            <Form.Item
              label={
                <>
                  <span>SCHEDULE</span>
                  <InfoTooltipWithTrigger
                    tooltip="The minimal signal granularity is limited by chart granularity. For example chart granularity is hour, the avaiable signal granularity is hour,day,week."
                    css={css`
                        margin-left: ${theme.gridUnit * 2}px;
                      `}
                    placement="top"
                  />
                </>
              }
              name="schedule"
              rules={[
                {
                  required: true,
                  message: t('Schedule is required'),
                },
              ]}
              initialValue={signalGranularity}
            >
              <SelectControl
                options={validSignalGranularityOptions}
                holder="Select a signal granularity"
                value={signalGranularity}
                onChange={(value: string) => handleSignalGranularityChange(value)}
                disabled={isReadonly}
                clearable={false}
              />
            </Form.Item>
          </div>
        </Col>
      </Row>

      <Row
        gutter={rowGutter}
        css={rowCss}
      >
        <Col span={24}>
          <ScheduleDelaySettingControl 
            delay={delay}
            onChange={(props: ScheduleDelaySettingProps, newState: ScheduleDelaySettingEntity) => {
              setDelay(newState.delay);
            }}
            isReadonly={isReadonly}
          />
        </Col>
      </Row>
    </div>
  );
});

export default withTheme(ScheduleSectionControl);