/* eslint-disable react/no-unused-state, prefer-destructuring, dot-notation, prettier/prettier, object-shorthand, no-else-return, arrow-body-style, no-unused-vars */
import {
  css,
  QueryData,
  supersetTheme,
  // @ts-ignore
  t,
  withTheme,
} from '@superset-ui/core';
import React, { useEffect, useState, memo } from 'react';
import { Col, Divider, Row } from 'src/components';
import { SignalStatus } from 'src/types/Signal';
import { Input } from 'src/components/Input';
import ErrorBoundary from 'src/components/ErrorBoundary';
import { Form } from 'antd';
import { ColumnMeta, InfoTooltipWithTrigger } from '@superset-ui/chart-controls';
import {
  SignalAlertConfigurationProps,
  SignalAlertConfigurationEntity,
  SignalAlertValueTypeEnum,
  // @ts-ignore
  SignalAlertValueModeEnum,
  QueryResponseDataEntity,
  QueryResponseValueEntity,
  DetailSectionEntity,
  DetailSectionProps,
  ScheduleSectionEntity,
  ScheduleSectionProps,
  SignalSectionEntity,
  SignalSectionProps,
  NotificationSectionEntity,
  NotificationSectionProps,
  MeasureSeriesOptionEntity,
  SignalMeasureContentEntity,
  TimeGranularity,
  STRING_TYPE_EMPTY_SPEC,
  // @ts-ignore
  DimensionSeriesValueDetailEntity,
  MeasureSeriesItemEntity,
} from './signalAlertsTypes';
import { generatorSignalSql, generatorSignalSqlV2 } from './signalSqlGenerator';

import NotificationSectionControl from './NotificationSectionControl';
import ScheduleSectionControl from './ScheduleSectionControl';
import DetailSectionControl from './DetailSectionControl';
import { CustomCollapse } from './CustomCollapse';
import SignalSectionControl, { convertDimensionSeriesToSeriesEntity, convertMeasureSeriesItemEntityToMeasureSeriesOptionEntity } from './SignalSectionControl';
// @ts-ignore
import { camelCaseKeysToUnderscore, underscoreToCamelCase } from './utils';
import { DEFAULT_DELAY } from './Constant';

const isVerbose = false;


/**
 * Returns an array of all series keys sorted by the sum of their values in descending order.
 * @param data - An array of QueryResponseDataEntity objects.
 * @returns An array of strings representing the keys of all series in the input data, sorted by the sum of their values in descending order.
 */
const getAllSeries = (data: QueryResponseDataEntity[]) => {
  const sortedData = data.sort((a: QueryResponseDataEntity, b: QueryResponseDataEntity) => {
    const asum = a.values.map((x: QueryResponseValueEntity) => x.y).reduce((acc: any, val: any) => acc + val);
    const bsum = b.values.map((x: QueryResponseValueEntity) => x.y).reduce((acc: any, val: any) => acc + val);
    return bsum - asum;
  });
  return sortedData.map((item: QueryResponseDataEntity) => item.key);
}

// get dimensions from params child property groupby
const getDimensions = (params: any) => {
  return (params?.groupby || []) as string[];
}


/**
 * Returns an array of measure names from the given SignalAlertsProps parameters.
 * @param formData - The parameters object containing the measurements array.
 * @returns An array of measure names.
 */
const getMeasuresFromFormData = (formData: any) => {
  const measures = formData?.measurements?.map((measurement: any) => measurement['MeasureName'] as string) || [] as string[];
  return measures;
}

function padTo2Digits(num: number) {
  return num.toString().padStart(2, '0');
}

export function formatDateToYYYYMMDD(date: Date) {
  // return (
  //   [ date.getFullYear(), padTo2Digits(date.getMonth() + 1), padTo2Digits(date.getDate()) ].join('-') + ' ' +
  //   [ padTo2Digits(date.getHours()), padTo2Digits(date.getMinutes()), padTo2Digits(date.getSeconds()) ].join(':')
  // );
  return (
    [date.getFullYear(), padTo2Digits(date.getMonth() + 1), padTo2Digits(date.getDate())].join('-')
  );
}

export function formatDateKeepTimeChangeZoneToUTC(date: Date) {
  return `${date.getFullYear()}-${padTo2Digits(date.getMonth() + 1)}-${padTo2Digits(date.getDate())}T00:00:00.000Z`;
}

export function formatISOStringDateToYYYYMMDD(isoDate: String) {
  // Extract the date and time components
  const datePart = isoDate.slice(0, 10); // yyyy-MM-dd
  // Concatenate the date and time with a space
  return `${datePart}`;
}

/**
 * Returns the top N series from the given data based on the sum of their y values.
 * @param data - The data to extract the top N series from.
 * @param n - The number of top series to extract.
 * @returns An array of the top N series keys.
 */
const getTopNPivotSeries = (data: QueryResponseDataEntity[], n: number) => {
  return getAllSeries(data).slice(0, n);
}

function getSignalMeasureContentListFromAddedMeasureSeriesConfigList(
  dimensions: string[],
  originalQuerySql: string,
  time_grain_sqla: string,
  granularity_sqla: string,
  columnsFormatInfo: ColumnMeta[],
  addedMeasureSeriesConfigList: MeasureSeriesItemEntity[],
  granularity_sqla_alias?: string,
  seriesList?: MeasureSeriesOptionEntity[],
  rolling_periods?: number,
) {
  if (addedMeasureSeriesConfigList.length > 0 && seriesList !== undefined && seriesList.length > 0) {
    const result: SignalMeasureContentEntity[] = [];
    const msl: MeasureSeriesOptionEntity[] = seriesList;

    addedMeasureSeriesConfigList.forEach(addedMeasureSeriesConfig => {
      const measure = addedMeasureSeriesConfig.measure;
      const dimensionSeries = addedMeasureSeriesConfig.dimensionSeries;
      const detectionSetting = {
        wholeMetricConfiguration: addedMeasureSeriesConfig.wholeMetricConfiguration,
        seriesOverrideConfigurations: addedMeasureSeriesConfig.seriesOverrideConfigurations,
        dimensionGroupOverrideConfigurations: addedMeasureSeriesConfig.dimensionGroupOverrideConfigurations,
      };

      const signalMeasureContent: SignalMeasureContentEntity = {
        // eslint-disable-next-line no-param-reassign, no-self-assign
        measure: measure,
        detectionSetting: JSON.stringify(detectionSetting),
        deleted: 0,
        dimension: JSON.stringify(dimensions),
        dimensionSeries: JSON.stringify(dimensionSeries),
        query: generatorSignalSql(
          // eslint-disable-next-line no-param-reassign, no-self-assign
          originalQuerySql,
          // eslint-disable-next-line no-param-reassign, no-self-assign
          msl.filter(item => {
            if (item.measure === measure) {
              if (item.series !== undefined && item.series.dimension !== undefined && Object.keys(item.series.dimension).length > 0) {
                const convertedSeries = convertDimensionSeriesToSeriesEntity(dimensionSeries);
                // check is the convertedSeries is equal item.series
                if (convertedSeries === undefined) {
                  return false;
                }
                const convertedSeriesDimension = convertedSeries.dimension;
                const itemSeriesDimension = item.series.dimension;
                if (convertedSeriesDimension === undefined || Object.keys(convertedSeriesDimension).length === 0) {
                  return false;
                }
                // compare is convertedSeriesDimension equals itemSeriesDimension
                const convertedSeriesDimensionKeys = Object.keys(convertedSeriesDimension);
                const itemSeriesDimensionKeys = Object.keys(itemSeriesDimension);
                if (convertedSeriesDimensionKeys.length !== itemSeriesDimensionKeys.length) {
                  return false;
                }
                for (let i = 0; i < convertedSeriesDimensionKeys.length; i += 1) {
                  const convertedSeriesDimensionKey = convertedSeriesDimensionKeys[i];
                  const convertedSeriesDimensionValue = convertedSeriesDimension[convertedSeriesDimensionKey];
                  const itemSeriesDimensionValue = itemSeriesDimension[convertedSeriesDimensionKey];
                  if (convertedSeriesDimensionValue !== itemSeriesDimensionValue) {
                    return false;
                  }
                }
              }
              return true;
            }
            return false
          }),
          // eslint-disable-next-line no-param-reassign, no-self-assign
          time_grain_sqla,
          // eslint-disable-next-line no-param-reassign, no-self-assign
          granularity_sqla,
          // eslint-disable-next-line no-param-reassign, no-self-assign
          true,
          // eslint-disable-next-line no-param-reassign, no-self-assign
          columnsFormatInfo,
          // eslint-disable-next-line no-param-reassign, no-self-assign
          granularity_sqla_alias,
          // eslint-disable-next-line no-param-reassign, no-self-assign
          rolling_periods,
        ),
      };
      result.push(signalMeasureContent);
    });
    return result;
  }
  return [];
}

const parseSeriesList = (series: string) => {
  return JSON.parse(series) as MeasureSeriesOptionEntity[];
};

const serializeSeriesList = (seriesList: MeasureSeriesOptionEntity[]) => {
  if (seriesList.length === 0) {
    return "[]";
  }
  return JSON.stringify(seriesList);
};

function convertMeasureSeriesOptionFromPivotSeries(pivotSeriesKey: string[] | string, measures: string[], dimensions: string[], columnsFormatInfo?: ColumnMeta[], stringTypeEmptySpec?: string) {
  // console.log("== SignalAlertConfigurationControl -> @convertMeasureSeriesOptionFromPivotSeries() -> @pivotSeriesKey == ");
  // console.log(pivotSeriesKey);
  // console.log("== SignalAlertConfigurationControl -> @convertMeasureSeriesOptionFromPivotSeries() -> @dimensions == ");
  // console.log(dimensions);

  // if (pivotSeriesKey.length === 0 || pivotSeriesKey.length !== dimensions.length + 1) {
  if (typeof pivotSeriesKey === 'string') {
    if (pivotSeriesKey.length === 0) {
      return undefined;
    }

    if (dimensions.length !== 0) {
      return undefined;
    }

    const measureSeriesOption: MeasureSeriesOptionEntity = {
      measure: pivotSeriesKey,
    };
    return measureSeriesOption;
  } else {
    // eslint-disable-next-line no-lonely-if
    if (pivotSeriesKey.length === 0) {
      return undefined;
    } else if (dimensions.length === 0) {
      const measureSeriesOption: MeasureSeriesOptionEntity = {
        measure: pivotSeriesKey[0],
      };
      return measureSeriesOption;
    } else {
      let measureSeriesOption: MeasureSeriesOptionEntity;
      let dimensionValues;
      if (measures.length === 1 && pivotSeriesKey[0] !== measures[0]) {
        measureSeriesOption = {
          measure: measures[0],
        };
        dimensionValues = pivotSeriesKey;
      } else {
        measureSeriesOption = {
          measure: pivotSeriesKey[0],
        };
        dimensionValues = pivotSeriesKey.slice(1, pivotSeriesKey.length);
      }
      measureSeriesOption.series = {};
      measureSeriesOption.series.dimension = {};
      for (let i = 0; i < dimensions.length; i += 1) {
        if (columnsFormatInfo && stringTypeEmptySpec) {
          const filteredString = columnsFormatInfo.filter((x) => x.column_name === dimensions[i] && x.type === 'STRING')
          if (filteredString.length > 0) {
            if (dimensionValues[i] === '' || dimensionValues[i].length === 0) {
              measureSeriesOption.series.dimension[dimensions[i]] = stringTypeEmptySpec;
            } else {
              measureSeriesOption.series.dimension[dimensions[i]] = dimensionValues[i];
            }
          } else {
            measureSeriesOption.series.dimension[dimensions[i]] = dimensionValues[i];
          }
        } else {
          measureSeriesOption.series.dimension[dimensions[i]] = dimensionValues[i];
        }
      }
      return measureSeriesOption;
    }
  }
};


/**
 * Generates a list of measure series options from an array of pivot top series keys.
 * @param pivotTopSeriesKeys An array of pivot top series keys.
 * @param dimensions An optional array of dimensions.
 * @returns A list of measure series options.
 */
function generateMeasureSeriesOptionListFromPivotTopSeriesKeys(
  pivotTopSeriesKeys: string[][],
  measures: string[],
  dimensions?: string[],
  columnsFormatInfo?: ColumnMeta[],
  stringTypeEmptySpec?: string
) {
  if (isVerbose) console.log("== SignalAlertConfigurationControl -> @generateMeasureSeriesOptionListFromPivotTopSeriesKeys() -> @pivotTopSeriesKeys == ");
  if (isVerbose) console.log(pivotTopSeriesKeys);
  if (isVerbose) console.log("== SignalAlertConfigurationControl -> @generateMeasureSeriesOptionListFromPivotTopSeriesKeys() -> @dimensions == ");
  if (isVerbose) console.log(dimensions);

  const measureSeriesOptionList: MeasureSeriesOptionEntity[] = [];
  for (let i = 0; i < pivotTopSeriesKeys.length; i += 1) {
    const measureSeriesOption = convertMeasureSeriesOptionFromPivotSeries(pivotTopSeriesKeys[i], measures, dimensions || [], columnsFormatInfo, stringTypeEmptySpec);
    if (measureSeriesOption !== undefined) {
      measureSeriesOptionList.push(measureSeriesOption);
    }
  }

  if (isVerbose) console.log("== SignalAlertConfigurationControl -> @generateMeasureSeriesOptionListFromPivotTopSeriesKeys() -> @measureSeriesOptionList == ");
  if (isVerbose) console.log(measureSeriesOptionList);
  if (isVerbose) console.log(JSON.stringify(measureSeriesOptionList));
  return measureSeriesOptionList;
}

/**
 * 
 * @param queryData 
 * @param params the params is an alias of form_data
 * @param maxSeriesNumber 
 */
const getMeasureSeriesOptionListFromQueryData = (
  queryData: QueryData,
  params: any,
  maxSeriesNumber: number,
  dimensions?: string[],
  columnsFormatInfo?: ColumnMeta[],
  stringTypeEmptySpec?: string) => {
  if (isVerbose) console.log("== SignalAlertConfigurationControl -> @getMeasureSeriesOptionListFromQueryData() -> @queryData == ");
  if (isVerbose) console.log(queryData);
  if (isVerbose) console.log("== SignalAlertConfigurationControl -> @getMeasureSeriesOptionListFromQueryData() -> @maxSeriesNumber == ");
  if (isVerbose) console.log(maxSeriesNumber);
  if (isVerbose) console.log("== SignalAlertConfigurationControl -> @getMeasureSeriesOptionListFromQueryData() -> @dimensions == ");
  if (isVerbose) console.log(dimensions);

  // the max seriesList size is equals maxSeriesNumber * measureNumber
  const data: any = queryData.data;
  const measures = getMeasuresFromFormData(params);
  const topNPivotSeries = getTopNPivotSeries(data, maxSeriesNumber * measures.length);
  const seriesList = generateMeasureSeriesOptionListFromPivotTopSeriesKeys(topNPivotSeries, measures, dimensions, columnsFormatInfo, stringTypeEmptySpec);

  if (isVerbose) console.log("== SignalAlertConfigurationControl -> @getMeasureSeriesOptionListFromQueryData() -> @data == ");
  if (isVerbose) console.log(data);
  if (isVerbose) console.log("== SignalAlertConfigurationControl -> @getMeasureSeriesOptionListFromQueryData() -> @params == ");
  if (isVerbose) console.log(params);
  if (isVerbose) console.log("== SignalAlertConfigurationControl -> @getMeasureSeriesOptionListFromQueryData() -> @measures == ");
  if (isVerbose) console.log(measures);
  if (isVerbose) console.log("== SignalAlertConfigurationControl -> @getMeasureSeriesOptionListFromQueryData() -> @topNPivotSeries == ");
  if (isVerbose) console.log(topNPivotSeries);
  if (isVerbose) console.log("== SignalAlertConfigurationControl -> @getMeasureSeriesOptionListFromQueryData() -> @seriesList == ");
  if (isVerbose) console.log(seriesList);

  if (seriesList === undefined || seriesList.length === 0) {
    return [];
  }
  return seriesList;
};

const filterOutMeasureNotExists = (seriesList: MeasureSeriesOptionEntity[], measures: string[]) => {
  const result: MeasureSeriesOptionEntity[] = [];
  seriesList.forEach(item => {
    if (measures.includes(item.measure)) {
      result.push(item);
    }
  });
  return result;
};

const getInitSeriesList = (
  maxSeriesNumber: number,
  queryData: QueryData,
  params: any,
  dimensions?: string[],
  series?: string,
  columnsFormatInfo?: ColumnMeta[],
  stringTypeEmptySpec?: string
) => {
  // series string exists, means it is come from before saved signal alert
  const storedSeriesList = [];
  if (series !== undefined && series !== "" && series !== "[]") {
    // parse and return directly
    const parsedSeriesList: MeasureSeriesOptionEntity[] = parseSeriesList(series);
    storedSeriesList.push(...parsedSeriesList);
  }
  // if series is not exists, means this is a new signal config, generate measureSeriesOptionList from queryData
  const generatedSeriesList = getMeasureSeriesOptionListFromQueryData(queryData, params, maxSeriesNumber, dimensions, columnsFormatInfo, stringTypeEmptySpec);
  // merge pasedSeriesList and generatedSeriesList and drop duplicated items
  const mergedSeriesList = storedSeriesList.concat(generatedSeriesList);
  // filter out the measure not exists in the params
  const filteredSeriesList = filterOutMeasureNotExists(mergedSeriesList, getMeasuresFromFormData(params));
  const finalSeriesListMap = new Map();
  filteredSeriesList.forEach((item: MeasureSeriesOptionEntity) => {
    finalSeriesListMap.set(JSON.stringify(item), item);
  });

  const result = Array.from(finalSeriesListMap.values());
  return result;
};

const SignalAlertConfigurationControl = memo(function SignalAlertConfigurationControl(props: SignalAlertConfigurationProps) {
  // const [measures, setMeasures] = useState<string[] | undefined>(getMeasures(params) || undefined);
  // const [slice, setSlice] = useState(props?.slice || undefined);
  // @ts-ignore
  const [params, setParams] = useState(props.params);
  // @ts-ignore
  const [dimensions, setDimensions] = useState<string[] | undefined>(getDimensions(params));
  const [signalMeasureContentList, setSignalMeasureContentList] = useState<SignalMeasureContentEntity[]>(props.signalMeasureContentList !== undefined ? props.signalMeasureContentList : []);
  // const [combinedDetectionSetting, setCombinedDetectionSetting] = useState<Record<string, MeasureAlertConfigurationEntity> | undefined>(
  //   getCombinedDetectionSettingFromSignalMeasureContentList(signalMeasureContentList)
  // );
  const [description, setDescription] = useState(props.description);
  const [subject, setSubject] = useState(props.subject);
  const [recipient, setRecipient] = useState<string | undefined>(props.recipient);
  const [signalName, setSignalName] = useState(props.signalName);
  const [mode, setMode] = useState(props.mode);

  const todayZeroOClock = formatDateKeepTimeChangeZoneToUTC(new Date());
  // @ts-ignore
  const [ingestionStartTime, setIngestionStartTime] = useState(props.ingestionStartTime !== undefined ? props.ingestionStartTime : todayZeroOClock);
  const [granularity, setGranularity] = useState(props.granularity !== undefined ? props.granularity : params['time_grain_sqla']);


  const [delay, setDelay] = useState(props.delay || DEFAULT_DELAY);

  // @ts-ignore
  const [isGranularityValid, setIsGranularityValid] = useState(Object.values(TimeGranularity).includes(granularity));
  const [signalGranularity, setSignalGranularity] = useState(props.signalGranularity !== undefined ? props.signalGranularity : granularity);

  // @ts-ignore
  const [maxSeriesNumber, setMaxSeriesNumber] = useState(props.maxSeriesNumber);
  // @ts-ignore
  const [seriesList, setSeriesList] = useState<MeasureSeriesOptionEntity[]>(
    getInitSeriesList(maxSeriesNumber, props.queryData, params, dimensions, props.series, props.columnsFormatInfo, STRING_TYPE_EMPTY_SPEC)
  );
  // @ts-ignore
  // const [addedMeasureSeriesOptionList, setAddedMeasureSeriesOptionList ] = useState<MeasureSeriesOptionEntity[]>([]);

  const getAddedMeasureSeriesConfigList = (signalMeasureContentEntityList: SignalMeasureContentEntity[]) => {
    const result: MeasureSeriesItemEntity[] = [];
    for (let i = 0; i < signalMeasureContentEntityList.length; i += 1) {
      const signalMeasureContentEntity = signalMeasureContentEntityList[i];
      const measureSeriesItem: MeasureSeriesItemEntity = {
        index: i,
        measure: signalMeasureContentEntity.measure,
        configType: 'series',
        dimensionSeries: underscoreToCamelCase(JSON.parse(signalMeasureContentEntity.dimensionSeries)),
        wholeMetricConfiguration: JSON.parse(signalMeasureContentEntity.detectionSetting).wholeMetricConfiguration,
        seriesOverrideConfigurations: [],
        dimensionGroupOverrideConfigurations: [],
        isExpand: i === 0,
      };
      result.push(measureSeriesItem);
    }
    return result;
  };
  const [addedMeasureSeriesConfigList, setAddedMeasureSeriesConfigList] = useState<MeasureSeriesItemEntity[]>(getAddedMeasureSeriesConfigList(signalMeasureContentList || []));
  if (isVerbose) console.log("== SignalAlertConfigurationControl -> @getInitSeriesList() -> @maxSeriesNumber == ");
  if (isVerbose) console.log(maxSeriesNumber);
  if (isVerbose) console.log("== SignalAlertConfigurationControl -> @getInitSeriesList() -> @props.queryData == ");
  if (isVerbose) console.log(props.queryData);
  if (isVerbose) console.log("== SignalAlertConfigurationControl -> @getInitSeriesList() -> @dimensions == ");
  if (isVerbose) console.log(dimensions);
  if (isVerbose) console.log("== SignalAlertConfigurationControl -> @getInitSeriesList() -> @props.series == ");
  if (isVerbose) console.log(props.series)
  if (isVerbose) console.log("== SignalAlertConfigurationControl -> #seriesList == ");
  if (isVerbose) console.log(seriesList);


  const [isReadonly, setIsReadonly] = useState(props.isReadonly || false);

  // only creator can access, for others readonly
  const [status, setStatus] = useState<number>(props.status === undefined ? SignalStatus.ACTIVE : props.status);

  const handleSignalNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setSignalName(value);
  };

  const handleDetailSectionChange = (detailSectionProps: DetailSectionProps, newState: DetailSectionEntity) => {
    setDescription(newState.description);
  };

  const handleScheduleSectionChange = (scheduleSectionProps: ScheduleSectionProps, newState: ScheduleSectionEntity) => {
    setGranularity(newState.granularity);
    setSignalGranularity(newState.signalGranularity);
    setStatus(newState.status);
    setDelay(newState.delay);
  };

  const handleSignalSectionChange = (signalSectionProps: SignalSectionProps, newState: SignalSectionEntity) => {
    setAddedMeasureSeriesConfigList(newState.addedMeasureSeriesConfigList);
    if (newState.addedMeasureSeriesConfigList.length > 0) {
      const initialzedSeriesList = newState.addedMeasureSeriesConfigList?.map(convertMeasureSeriesItemEntityToMeasureSeriesOptionEntity) || [];
      console.log("== SignalAlertConfigurationControl -> @handleSignalSectionChange() -> @initialzedSeriesList == ");
      console.log(initialzedSeriesList);
      const granularity_sqla_alias = (props.queryData.colnames as string[]).find(item => item === '__timestamp') ? '__timestamp' : undefined;
      setSignalMeasureContentList(getSignalMeasureContentListFromAddedMeasureSeriesConfigList(
        dimensions || [],
        props.queryData.query,
        params['time_grain_sqla'],
        params.granularity_sqla,
        props.columnsFormatInfo,
        newState.addedMeasureSeriesConfigList,
        granularity_sqla_alias,
        // TODO FIXME should not use the seriesList, we need to use the initialzedSeriesList, but hte initialzedSeriesList is not ready yet
        initialzedSeriesList,
        params.rolling_periods,
      ));
    } else {
      setSignalMeasureContentList([]);
    }
  };

  const handleNotificationSectionChange = (notificationSectionProps: NotificationSectionProps, newState: NotificationSectionEntity) => {
    setMode(newState.mode);
    setRecipient(newState.recipient);
    setSubject(newState.subject);
  };

  const getMeasureFromParams = (params: any) => {
    return JSON.stringify(
      params?.measurements?.map((measurement: any) => measurement['MeasureName'] as string) ||
      [] as string[]
    );
  };

  const getDimensionsFromParams = (params: any) => {
    return JSON.stringify((params?.groupby || []) as string[]);
  };

  const getData = (): SignalAlertConfigurationEntity => {
    const granularity_sqla_alias = (props.queryData.colnames as string[]).find(item => item === '__timestamp') ? '__timestamp' : undefined;
    const propsTyped = props as SignalAlertConfigurationEntity;
    const initialzedSeriesList = addedMeasureSeriesConfigList?.map(convertMeasureSeriesItemEntityToMeasureSeriesOptionEntity) || [];
    const result: SignalAlertConfigurationEntity = {
      id: props.id,
      signalName: signalName,
      description: description,
      subject: subject,
      recipient: recipient,
      mode: mode,
      measure: getMeasureFromParams(params),
      dimension: getDimensionsFromParams(params),
      query: generatorSignalSqlV2(
        props.queryData.query,
        initialzedSeriesList,
        params['time_grain_sqla'],
        params.granularity_sqla,
        true,
        props.columnsFormatInfo,
        granularity_sqla_alias,
        // TODO FIXME should not use the seriesList, we need to use the initialzedSeriesList, but hte initialzedSeriesList is not ready yet
        params.rolling_periods,
      ),
      series: serializeSeriesList(seriesList),
      status: status,
      dbsIdFk: propsTyped.dbsIdFk,
      signalMeasureContentList: signalMeasureContentList,
      sliceIdFk: props.params['slice_id'],
      ingestionStartTime: ingestionStartTime,
      granularity: granularity,
      signalGranularity: signalGranularity,
      signalType: SignalAlertValueTypeEnum.ALERT,
      sliceUrl: propsTyped.sliceUrl,
      owners: propsTyped.owners,
      slice: propsTyped.slice,
      params: JSON.stringify(params),
      queryData: propsTyped.queryData,
      delay: delay,
    }
    return result;
  }

  useEffect(() => {
    props.onChange(props, getData());
  }, [signalName, recipient, ingestionStartTime, granularity, signalGranularity, signalMeasureContentList, mode, subject, description, status, delay]);

  useEffect(() => {
    setIsReadonly(props.isReadonly);
  }, [props.isReadonly]);

  useEffect(() => {
    setSeriesList(getInitSeriesList(maxSeriesNumber, props.queryData, params, dimensions, props.series, props.columnsFormatInfo, STRING_TYPE_EMPTY_SPEC));
  }, [props.queryData]);


  const theme = supersetTheme;
  const rowCss = css`
    display: flex;
    align-items: center;
    margin-top: ${theme.gridUnit * 4}px;
  `;

  const defaultBordered = true;
  const defaultGhost = true;
  const defaultIsExpand = true;
  return (
    <>
      {!isGranularityValid &&
        <div>
          <ErrorBoundary>
            Not support
          </ErrorBoundary>
        </div>
      }

      {isGranularityValid &&
        <div>
          <Row
            gutter={[16, 16]}
            css={rowCss}
          >
            <Col span={24}>
              <div
                css={{
                  display: 'flex',
                  paddingTop: theme.gridUnit,
                  paddingLeft: theme.gridUnit * 4,
                  paddingRight: theme.gridUnit * 4,
                }}
              >
                TITLE
              </div>
              <div
                css={{
                  display: 'flex',
                  paddingTop: theme.gridUnit,
                  paddingLeft: theme.gridUnit * 4,
                  paddingRight: theme.gridUnit * 4,
                }}
              >
                <Form.Item
                  label={
                    <>
                      <span>SIGNAL NAME</span>
                      <InfoTooltipWithTrigger
                        tooltip="Signal Name is required, and the signal name will be used in alert notification in Email or Teams message. Good naming helps you find it in your message"
                        css={css`
                          margin-left: ${theme.gridUnit * 2}px;
                        `}
                        placement="top"
                      />
                    </>
                  }
                  name="Signal Name"
                  rules={[
                    {
                      required: true,
                      message: t('Signal Name is required'),
                    },
                    {
                      pattern: /^[A-Za-z0-9!"#$%&'()*+,-.:;<=>?@[\]^_{|}`~ ]+$/g,   // help me generate a regex to validate the signal name only could support english char, english punctuation and number
                      message: t('Invalid Signal Name'),
                    }
                  ]}
                  initialValue={signalName}
                  css={{
                    width: '100%',
                  }}
                >
                  <Input
                    placeholder="Signal Name"
                    value={signalName}
                    onChange={handleSignalNameChange}
                    disabled={isReadonly}
                  />
                </Form.Item>
              </div>
              <div
                css={{
                  display: 'flex',
                  paddingTop: theme.gridUnit,
                  paddingLeft: theme.gridUnit * 4,
                  paddingRight: theme.gridUnit * 4,
                }}
              >
                Created Time: {props.ingestionStartTime ? formatISOStringDateToYYYYMMDD(props.ingestionStartTime) : formatDateToYYYYMMDD(new Date())}
              </div>
            </Col>
          </Row>

          <Divider />

          <CustomCollapse
            bordered={defaultBordered}
            ghost={defaultGhost}
            isExpand={defaultIsExpand}
            extra={
              <span
                css={{
                  display: 'inline-flex',
                  alignSelf: 'center',
                  paddingTop: theme.gridUnit,
                }}
              >
                Detail
              </span>
            }
            // eslint-disable-next-line react/no-children-prop
            children={
              <DetailSectionControl
                description={props.description}
                ingestionStartTime={ingestionStartTime}
                createdBy={props.createdBy}
                isReadonly={isReadonly}
                onChange={(props: DetailSectionProps, newState: DetailSectionEntity) => handleDetailSectionChange(props, newState)}
              />
            }
          />

          <Divider />

          <CustomCollapse
            bordered={defaultBordered}
            ghost={defaultGhost}
            isExpand={defaultIsExpand}
            extra={
              <span
                css={{
                  display: 'inline-flex',
                  alignSelf: 'center',
                  paddingTop: theme.gridUnit,
                }}
              >
                Signal
              </span>
            }
            // eslint-disable-next-line react/no-children-prop
            children={
              <SignalSectionControl
                seriesList={seriesList}
                addedMeasureSeriesConfigList={addedMeasureSeriesConfigList}
                isReadonly={isReadonly}
                columnsFormatInfo={props.columnsFormatInfo}
                onChange={(props: SignalSectionProps, newState: SignalSectionEntity) => handleSignalSectionChange(props, newState)}
              />
            }
          />

          <Divider />

          <CustomCollapse
            bordered={defaultBordered}
            ghost={defaultGhost}
            isExpand={defaultIsExpand}
            extra={
              <span
                css={{
                  display: 'inline-flex',
                  alignSelf: 'center',
                  paddingTop: theme.gridUnit,
                }}
              >
                Schedule
              </span>
            }
            // eslint-disable-next-line react/no-children-prop
            children={
              <ScheduleSectionControl
                status={status}
                granularity={granularity}
                signalGranularity={props.signalGranularity}
                delay={delay}
                isReadonly={isReadonly}
                onChange={(props: ScheduleSectionProps, newState: ScheduleSectionEntity) => handleScheduleSectionChange(props, newState)}
              />
            }
          />

          <Divider />

          <CustomCollapse
            ghost={defaultGhost}
            bordered={defaultBordered}
            isExpand={defaultIsExpand}
            extra={
              <span
                css={{
                  display: 'inline-flex',
                  alignSelf: 'center',
                  paddingTop: theme.gridUnit,
                }}
              >
                Notification
              </span>
            }
            // eslint-disable-next-line react/no-children-prop
            children={
              <NotificationSectionControl
                maxRecipientCount={10}
                subject={subject}
                recipient={recipient}
                mode={mode}
                isReadonly={isReadonly}
                onChange={(props: NotificationSectionProps, newState: NotificationSectionEntity) => handleNotificationSectionChange(props, newState)}
              />
            }
          />

        </div>
      }
    </>
  );
});


export default withTheme(SignalAlertConfigurationControl);