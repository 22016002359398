import { v4 as uuidv4 } from 'uuid';
import { IAdvancedMetricFilter } from './AdvancedMetricFilter.type';

export type FilterGroupOperator = 'AND' | 'OR';

export function generateFilterKey(): string {
  return uuidv4().toString();
}

/* eslint-disable no-underscore-dangle */
export default class AdvancedMetricFilterGroup {
  private _filters: IAdvancedMetricFilter[] = [];

  private _operator: FilterGroupOperator = 'AND';

  private _groups: AdvancedMetricFilterGroup[] = [];

  private _parent?: AdvancedMetricFilterGroup;

  private _key: string = generateFilterKey();

  constructor(parent?: AdvancedMetricFilterGroup) {
    this._parent = parent;
  }

  public get filters() {
    return this._filters;
  }

  public get operator() {
    return this._operator;
  }

  public set operator(operator: FilterGroupOperator) {
    this._operator = operator;
  }

  public get groups() {
    return this._groups;
  }

  public get parent() {
    return this._parent;
  }

  public set parent(parent: AdvancedMetricFilterGroup | undefined) {
    this._parent = parent;
  }

  public get key() {
    return this._key;
  }

  public addFilter(filter: IAdvancedMetricFilter): void {
    this._filters.push(filter);
  }

  public addFilterGroups(filterGroup: AdvancedMetricFilterGroup): void {
    this._groups.push(filterGroup);
  }

  public removeFilter(filterToBeRemoved: IAdvancedMetricFilter): void {
    this._filters = this._filters.filter(
      filter => filterToBeRemoved.key !== filter.key,
    );
    if (
      this._filters.length === 0 &&
      this._groups.length === 0 &&
      !!this._parent
    ) {
      this._parent._groups = this._parent.groups.filter(
        group => group.key !== this._key,
      );
      // this._parent = undefined;
    }
  }

  public switchGroupOperator(): void {
    if (this._operator === 'AND') {
      this._operator = 'OR';
    } else {
      this._operator = 'AND';
    }
  }
}
