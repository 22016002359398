/**
 * Licensed to the Apache Software Foundation (ASF) under one
 * or more contributor license agreements.  See the NOTICE file
 * distributed with this work for additional information
 * regarding copyright ownership.  The ASF licenses this file
 * to you under the Apache License, Version 2.0 (the
 * "License"); you may not use this file except in compliance
 * with the License.  You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 */
import React from 'react';
import ControlHeader from 'src/explore/components/ControlHeader';
import { Input } from 'src/components/Input';

type InputValueType = string | number;

export interface TextControlv2Props<T extends InputValueType = InputValueType> {
  label?: string;
  disabled?: boolean;
  onChange: (value: T) => {};
  onFocus?: () => {};
  placeholder?: string;
  value?: T | null;
  renderTrigger?: boolean;
}

export default class TextControlv2 extends React.Component<TextControlv2Props>{
  onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    // this.setState({value});
    this.props.onChange(value);
  };

  render() {
    return (
      <div>
        <ControlHeader {...this.props} />
        <Input
          type="text"
          data-test="inline-name"
          placeholder={this.props.placeholder}
          onChange={this.onChange}
          onFocus={this.props.onFocus}
          value={this.props.value || ''}
          disabled={this.props.disabled}
          aria-label={this.props.label}
        />
      </div>
    );
  }
}
