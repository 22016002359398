/* eslint-disable */
import React from 'react';
import {
  css,
} from '@superset-ui/core';

export const ImportantAsterisk = function() {
  const cssBasic = css`
    color: red;
  `;
  return (
    <>
      <span css={cssBasic}>*</span>  
    </>
  );
};


