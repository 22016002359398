/* eslint-disable react/no-unused-state, prefer-destructuring, dot-notation, prettier/prettier, no-unused-vars */
import {
  css,
  supersetTheme,
  t,
  withTheme,
} from '@superset-ui/core';
import React, { useEffect, useState, memo } from 'react';
import { Col, Row, } from 'src/components';
import SelectControl from 'src/explore/components/controls/SelectControl';
import {
  AnomalyDetectorDirectionEnum,
  ChangeThresholdConditionProps,
  ChangeThresholdConditionEntity,
  SuppressConditionEntity,
} from 'src/explore/components/controls/SignalAlertsControl/signalAlertsTypes';
import { InputNumber } from 'src/components/Input';
import { InfoTooltipWithTrigger } from '@superset-ui/chart-controls';


const ANOMALY_TOOLTIP = t(
  [
    'Some metrics are noisy, especially for fine granularity metrics. ',
    'Customers can set \'Do not report anomaly until\' x% of last y points are detected as anomalies. ',
    'A data point is considered as an anomaly only when meeting this criterion.',
  ].join());

const COMPARE_POINTS_TOOLTIP = t(
  [
    'Compared to the previous points, ',
    'the point is considered as an anomaly if the change percentage of point value is in or out of the range.',
  ].join());

const HOW_MANY_POINT_TOOLTIP = t(
  [
    'How many points the detector should look back.'
  ].join());


const ChangeThresholdControl = memo(function ChangeThresholdControl(props: ChangeThresholdConditionProps) {
  const anomalyDetectorDirectionOptions = [
    {"label": "Within the threshold", "value": `${AnomalyDetectorDirectionEnum.BOTH}-within`, "key": `k-${AnomalyDetectorDirectionEnum.BOTH}-within`},
    {"label": "Out of the threshold", "value": `${AnomalyDetectorDirectionEnum.BOTH}-out`, "key": `k-${AnomalyDetectorDirectionEnum.BOTH}-out`},
    {"label": "Below the threshold", "value": AnomalyDetectorDirectionEnum.DOWN, "key": `k-${AnomalyDetectorDirectionEnum.DOWN}`},
    {"label": "Above the threshold", "value": AnomalyDetectorDirectionEnum.UP, "key": `k-${AnomalyDetectorDirectionEnum.UP}`},
  ];
  
  const [isReadonly, setIsReadonly] = useState(props.isReadonly || false);
  const [changePercentage, setChangePercentage] = useState(props.changePercentage);
  const [shiftPoint, setShiftPoint] = useState(props.shiftPoint);
  const [withinRange, setWithinRange] = useState(props.withinRange);
  const [anomalyDetectorDirection, setAnomalyDetectorDirection] = useState(props.anomalyDetectorDirection);
  const [minRatio, setMinRatio] = useState(props.suppressCondition?.minRatio || 1);
  const [minNumber, setMinNumber] = useState(props.suppressCondition?.minNumber || 1);

  const handleChangePercentageChange = (value: number) => {
    if (!Number.isNaN(Number(value))) {
      setChangePercentage(Number(value));
    }
  }

  const handleShiftPointChange = (value: number) => {
    if (!Number.isNaN(Number(value))) {
      setShiftPoint(Number(value));
    }
  }

  const handleSetMinRatioChange = (value: number) => {
    if (!Number.isNaN(Number(value))) {
      setMinRatio(Number(value));
    }
  }

  const handleSetMinNumberChange = (value: number) => {
    if (!Number.isNaN(Number(value))) {
      setMinNumber(Number(value));
    }
  }
  
  const handleAnomalyDetectorDirectionChange = (value: string) => {
    if(value === `${AnomalyDetectorDirectionEnum.BOTH}-within`){
      setWithinRange(true);
      setAnomalyDetectorDirection(`${AnomalyDetectorDirectionEnum.BOTH}`);
    } else if (value === `${AnomalyDetectorDirectionEnum.BOTH}-out`) {
      setWithinRange(false);
      setAnomalyDetectorDirection(`${AnomalyDetectorDirectionEnum.BOTH}`);
    } else {
      setWithinRange(false);
      setAnomalyDetectorDirection(value);
    }
  }

  const getDetectorDirectionOptionValue = (anomalyDetectorDirection?:string) => {
    if (anomalyDetectorDirection === `${AnomalyDetectorDirectionEnum.BOTH}`) {
      const suffix = withinRange ? '-within' : '-out';
      return anomalyDetectorDirectionOptions.find(option => option.value === `${AnomalyDetectorDirectionEnum.BOTH}${suffix}`)?.value;
    }
    return anomalyDetectorDirectionOptions.find(option => option.value === anomalyDetectorDirection)?.value;
  }
  
  const getData = (): ChangeThresholdConditionEntity => {
    const suppressCondition: SuppressConditionEntity = {
      minRatio,
      minNumber: minNumber || 1, // set default value of 1 if minNumber is undefined
    };

    return {
      changePercentage,
      shiftPoint,
      withinRange,
      anomalyDetectorDirection,
      suppressCondition,
    };
  };

  useEffect(() => {
    props.onChange(props, getData());
  }, [changePercentage, shiftPoint, withinRange, anomalyDetectorDirection, minRatio, minNumber]);

  useEffect(() => {
    setIsReadonly(props.isReadonly);
  }, [props])

  const theme = supersetTheme;
  const rowCss = css`
    display: flex;
    align-items: center;
    margin-top: ${theme.gridUnit * 2}px;
  `;

  return (
    <>
      <Row
        css={{rowCss}}
      >
        <Col span={5}>
          <div
            css={{
              display: 'inline-flex',
              flex: 'auto',
              marginTop: theme.gridUnit,
            }}
          >
            {t('Change')}
          </div>
        </Col>
        <Col span={19}>
          <SelectControl
            css={{               
              marginRight: theme.gridUnit * 2,
              marginLeft: theme.gridUnit * 2,
              marginTop: theme.gridUnit, 
            }}
            options={anomalyDetectorDirectionOptions}
            value={getDetectorDirectionOptionValue(anomalyDetectorDirection)}
            onChange={handleAnomalyDetectorDirectionChange}
            clearable={false}
            disabled={isReadonly}
          />
        </Col>
        <Col span={24} css={{marginTop: theme.gridUnit * 2}}>
          <div
            css={{
              display: 'inline-flex',
              flex: 'auto',
              marginTop: theme.gridUnit,
            }}
          >
            {t('+/-')}
          </div>
          <div
            css={{
              flex: 'auto',
              display: 'inline-flex',
              marginRight: theme.gridUnit * 2,
              marginLeft: theme.gridUnit * 2,
              marginTop: theme.gridUnit,
            }}
          >
            <InputNumber
              min={0}
              value={changePercentage}
              onChange={handleChangePercentageChange}
              disabled={isReadonly}
              formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              parser={(value) => value!.replace(/,*/g, '')}
            />
            <InfoTooltipWithTrigger
              tooltip={COMPARE_POINTS_TOOLTIP}
              css={css`
                margin-left: ${theme.gridUnit}px;
                align-self: center;
              `}
              placement="top"
            />
          </div>
          <div
            css={{
              display: 'inline-flex',
              flex: 'auto',
              marginTop: theme.gridUnit,
            }}
          >
            {t('% over previous')}
            <InfoTooltipWithTrigger
              tooltip={HOW_MANY_POINT_TOOLTIP}
              css={css`
                margin-left: ${theme.gridUnit}px;
                align-self: center;
              `}
              placement="top"
            />
          </div>
          <div
            css={{
              flex: 'auto',
              display: 'inline-flex',
              marginRight: theme.gridUnit * 2,
              marginLeft: theme.gridUnit * 2,
              marginTop: theme.gridUnit,
            }}
          >
            <InputNumber
              min={1}
              value={shiftPoint}
              onChange={handleShiftPointChange}
              disabled={isReadonly}
              formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              parser={(value) => value!.replace(/,*/g, '')}
            />
          </div>
          <div
            css={{
              display: 'inline-flex',
              flex: 'auto',
              marginTop: theme.gridUnit,
            }}
          >
            {t('points is an anomaly.')}
          </div>
        </Col>
        <Col span={24} css={{marginTop: theme.gridUnit * 4}}>
          <div
            css={{
              display: 'inline-flex',
              flex: 'auto',
              marginTop: theme.gridUnit,
            }}
          >
            {t('Do not report anomaly until')}
          </div>
          <div
            css={{
              flex: 'auto',
              display: 'inline-flex',
              marginRight: theme.gridUnit * 2,
              marginLeft: theme.gridUnit * 2,
              marginTop: theme.gridUnit,
            }}
          >
            <InputNumber
              min={0}
              max={100}
              value={minRatio}
              onChange={handleSetMinRatioChange}
              disabled={isReadonly}
            />
          </div>
          <div
            css={{
              display: 'inline-flex',
              flex: 'auto',
              marginTop: theme.gridUnit,
            }}
          >
            {t('% of latest')}
          </div>
          <div
            css={{
              flex: 'auto',
              display: 'inline-flex',
              marginRight: theme.gridUnit * 2,
              marginLeft: theme.gridUnit * 2,
              marginTop: theme.gridUnit,
            }}
          >
            <InputNumber
              min={1}
              value={minNumber}
              onChange={handleSetMinNumberChange}
              disabled={isReadonly}
              formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              parser={(value) => value!.replace(/,*/g, '')}
            />
          </div>
          <div
            css={{
              display: 'inline-flex',
              flex: 'auto',
              marginTop: theme.gridUnit,
            }}
          >
            {t('points are detected as anomalies.')}
            <InfoTooltipWithTrigger
              tooltip={ANOMALY_TOOLTIP}
              css={css`
                margin-left: ${theme.gridUnit}px;
                align-self: center;
              `}
              placement="top"
            />
          </div>
        </Col>
      </Row>
    </>
  );
});

export default withTheme(ChangeThresholdControl);