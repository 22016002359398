/* eslint-disable react/no-unused-state, prefer-destructuring, dot-notation, prettier/prettier, arrow-body-style, object-shorthand, no-unused-vars */
import {
  css,
  supersetTheme,
  t,
  withTheme,
} from '@superset-ui/core';
import React, { useEffect, useState, memo } from 'react';
import { Col, Row, Select } from 'src/components';
import SelectControl from 'src/explore/components/controls/SelectControl';
import { Gutter } from 'antd/lib/grid/row';
import { Input } from 'src/components/Input';
import { Form } from 'antd';
import { InfoTooltipWithTrigger } from '@superset-ui/chart-controls';
import {
  SignalAlertValueModeEnum,
  NotificationSectionEntity,
  NotificationSectionProps,
  // @ts-ignore
  SINGAL_ALERT_AILAS,
  InValue,
} from './signalAlertsTypes';

import { ImportantAsterisk } from './ImportantAsterisk';


const isDisableSubject = true;

/**
 * Handle signal alert configuration section configuration,
 * include recipient|mode|subject configuration
 */
const NotificationSectionControl = memo(function NotificationSectionControl(props: NotificationSectionProps) {
  // @ts-ignore
  const [maxRecipientCount, setMaxRecipientCount] = useState(props.maxRecipientCount !== undefined ? props.maxRecipientCount : 500);
  // first time get recipient from props, because the recipient could be fetch from signal alert record user had created
  const [recipient , setRecipient] = useState<string | undefined>(props.recipient);
  const [mode, setMode] = useState(props.mode ? props.mode : SignalAlertValueModeEnum.EMAIL);
  const [subject, setSubject] = useState(props.subject);
  const [isReadonly, setIsReadonly] = useState(props.isReadonly);

  const handleModeChange = (value:string[]) => {
    if (value.length === 0) {
      setMode('');
    } else {
      setMode(value.join(';'));
    }
  };

  const handleSubjectChange = (value:string) => {
    setSubject(value);
  };

  const getData = (): NotificationSectionEntity => {
    const result: NotificationSectionEntity = {
      recipient: recipient,
      mode: mode,
      subject: subject,
    };
    return result;
  };

  useEffect(() => {
    setIsReadonly(props.isReadonly);
  }, [props.isReadonly]);

  useEffect(() => {
    props.onChange(props, getData());
  }, [recipient, mode, subject]);

  const theme = supersetTheme;
  const rowCss = css`
    display: flex;
    align-items: center;
    margin-top: ${theme.gridUnit * 2}px;
  `;
  const rowGutter:[Gutter, Gutter] = [16, 16];
  return (
    <div>
      {!isDisableSubject &&
        <Row
          gutter={rowGutter} 
          css={rowCss}
        >
          <Col span={24}>
            <div>SUBJECT <ImportantAsterisk /></div>
            <div>
              <span>
                {!isReadonly ? (
                  <Input 
                    placeholder="Subject"
                    value={subject}
                    onChange={(e) => handleSubjectChange(e.target.value)}
                  />
                  ) : (
                  subject
                )}
              </span>
            </div>
          </Col>
        </Row>
      }
      <Row
        gutter={rowGutter} 
        css={rowCss}
      >
        <Col span={24}>
          <div>
            <Form.Item
              label={
                <>
                  <span>RECIPIENT</span>
                  <InfoTooltipWithTrigger
                      tooltip="Support multiple recipients, please input email address format like <alias>@microsoft.com."
                      css={css`
                        margin-left: ${theme.gridUnit * 2}px;
                      `}
                      placement="top"
                  />
                </>
              }
              name="recipient"
              rules={[
                {
                  required: true,
                  message: t('Recipient is required'),
                },
                {
                  validator: (_, value:InValue[]) =>
                    value?.every(item => /^[^\s@]+@microsoft\.com$/.test(item.value || '')) ? Promise.resolve() : Promise.reject(new Error('Should be microsoft email address')),
                },
              ]}
              initialValue={recipient && JSON.parse(recipient).map((mail: string) => { return {label: mail, value: mail}})}
            >
              <Select
                labelInValue
                options={recipient && JSON.parse(recipient).map((mail: string) => { return {label: mail, value: mail}})}
                maxTagCount={maxRecipientCount}
                mode='multiple'
                value={recipient && JSON.parse(recipient).map((mail: string) => { return {label: mail, value: mail}})}
                onChange={newValue => {
                  if(newValue !== null || newValue !== undefined || Array.isArray(newValue) && (newValue as any[]).length !== 0) {
                    const newRecipient = JSON.stringify((newValue as InValue[]).map(value => value.value));
                    setRecipient(newRecipient);
                  } else {
                    setRecipient(undefined);
                  }
                }}
                ariaLabel={t('Input value')}
                placeholder={t('<alias>@microsoft.com')}
                filterOption
                showArrow={false}
                allowNewOptions
                disabled={isReadonly}
                allowClear={false}
              />
            </Form.Item>
          </div>
        </Col>
      </Row>
      <Row
        gutter={rowGutter}
        css={rowCss}
      >
        <Col span={24}>
          <div>
            <Form.Item
              label={
                <>
                  <span>MODE</span>
                  <InfoTooltipWithTrigger
                      tooltip="Support two modes: Email and Teams."
                      css={css`
                        margin-left: ${theme.gridUnit * 2}px;
                      `}
                      placement="top"
                  />
                </>
              }
              name="mode"
              rules={[
                {
                  required: true,
                  message: t('Notify mode is required'),
                },
              ]}
              initialValue={(mode && mode.length > 0) ? mode.split(';') : []}
            >
              <SelectControl
                options={[{label: "Email", value: "Email"}, {label: "Teams", value: "Teams"}]}
                holder="Select a mode"
                value={(mode && mode.length > 0) ? mode.split(';') : []}
                isMulti
                onChange={(value: string[]) => handleModeChange(value)}
                disabled={isReadonly}
                clearable={false}
              />
            </Form.Item>
          </div>
        </Col>
      </Row>
    </div>  
  );
});


export default withTheme(NotificationSectionControl);