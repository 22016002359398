import {
    QueryData,
} from '@superset-ui/core';
import {
    QueryResponseDataEntity,
} from './signalAlertsTypes';


export const normalizeQueryData = (queryData: QueryData) => {
    if (queryData.data.length === 0) {
      return queryData;
    } 
  
    if (queryData.data.length === 0) {
      return queryData;
    } 
  
    if (queryData.data[0].values) {
      // LegacyQueryData format
      return queryData;
    }
    // the ChartDataResponseResult format
    const normalizedQueryData = {
      ...queryData
    };
  
    const queryResponseDataEntityList: QueryResponseDataEntity[] = [];
  
    // 1. first data structure convert, from DataRecord[] to { key:string, values:QueryResponseValueEntity[] }
    const keyQueryResponseValueEntityMap = {}
    // eslint-disable-next-line no-restricted-syntax
    for (const dataRecord of queryData.data) {
      // eslint-disable-next-line prefer-const, no-underscore-dangle
      let __timestamp = 0;
      // eslint-disable-next-line prefer-const
      let keyValueMap = {};
  
      // prepare y, and dummy series
      Object.keys(dataRecord).forEach((key) => {
        if (key === '__timestamp') {
          __timestamp = dataRecord[key];
        } else {
          keyValueMap[key] = { 
            'y': dataRecord[key], 
            'series': -1
          };
        } 
      });
  
      // prepare x, and generate the data to keyQueryResponseValueEntityMap
      Object.keys(keyValueMap).forEach((key) => {
        // eslint-disable-next-line no-underscore-dangle, dot-notation
        keyValueMap[key]['x'] = __timestamp;
        if (keyQueryResponseValueEntityMap[key] === undefined) {
          keyQueryResponseValueEntityMap[key] = {
            'key': key.split(', '),
            'values': [keyValueMap[key]]
          };
        } else {
          // eslint-disable-next-line no-underscore-dangle, dot-notation
          keyQueryResponseValueEntityMap[key]['values'].push(keyValueMap[key]);
        }
      });   
    }
  
    // 2. second data structure convert
    Object.keys(keyQueryResponseValueEntityMap).forEach((key) => {
      queryResponseDataEntityList.push(keyQueryResponseValueEntityMap[key]);
    });
  
    // 3. set the queryResponseDataEntityList to normalizedQueryData.data
    normalizedQueryData.data = queryResponseDataEntityList;
    return normalizedQueryData;
  }