/**
 * <code>
 * {
 *   "max": {
 *     "value": "20",
 *     "type": "day",
 *     "message": "The maximum date range is 20 days"
 *   }
 * }
 * </code>
 * The above JSON configuration will limit the date range to 20 days. If the user tries to select a date range greater than 20 days, an error message will be displayed.
 * This information will be put in dataset's <code>extra</code> property. For example:
 * The extra field JSON content can be
 * <code>
 * {
 *   "query_time_range": {
 *      "max": {
 *        "value": "20",
 *        "type": "day",
 *        "message": "The maximum date range is 20 days"
 *      }
 *    }
 * }
 * </code>
 *
 * @param milliseconds - The number of days in the date range
 * @param queryTimeRange - The query_time_range field of the dataset.extra, please look at the models.py
 */
export default function checkQueryTimeRange(
  milliseconds: number,
  queryTimeRange: any,
) {
  const DURATION_DAY = 24 * 60 * 60 * 1000;
  if (queryTimeRange) {
    const { max } = queryTimeRange;
    if (max) {
      const { value, type, message } = max;
      if (
        type === 'day' &&
        milliseconds > Number.parseFloat(value) * DURATION_DAY
      ) {
        return message;
      }
    }
  }
  return undefined;
}
