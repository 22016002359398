/* eslint-disable */

/**
 * Converts all keys in a given object from camelCase to underscore_case recursively.
 * @param obj - The object to convert keys for.
 * @returns A new object with all keys converted to underscore_case.
 */
export function camelCaseKeysToUnderscore(obj: any): any {
    if (typeof obj !== 'object' || obj === null) {
      return obj;
    }
  
    if (Array.isArray(obj)) {
      return obj.map(camelCaseKeysToUnderscore);
    }

    const result: any = {};
    
    Object.keys(obj).forEach((key) => {
      const underscoreKey = key.replace(/([a-z])([A-Z])/g, '$1_$2').toLowerCase();
      result[underscoreKey] = camelCaseKeysToUnderscore(obj[key]);
    });

    return result;
  }
  
  /**
   * Converts an object's keys from underscore to camel case recursively.
   * @param obj - The object to convert.
   * @returns The converted object.
   */
export function underscoreToCamelCase(obj: any):any  {
  if (typeof obj !== 'object' || obj === null) {
    return obj;
  }

  if (Array.isArray(obj)) {
    return obj.map(underscoreToCamelCase);
  }

  const result: any = {};
  Object.keys(obj).forEach((key) => {
    const camelCaseKey = key.replace(/_([a-z])/g, (match, letter) => letter.toUpperCase());
    result[camelCaseKey] = underscoreToCamelCase(obj[key]);
  });

  return result;
}

