/* eslint-disable react/no-unused-state, prefer-destructuring, dot-notation, prettier/prettier, object-shorthand, arrow-body-style, no-unused-vars */
import {
  css,
  supersetTheme,
  withTheme,
} from '@superset-ui/core';
import React, { useEffect, useState, memo, useRef } from 'react';
import { Col, Row } from 'src/components';
import Icons from 'src/components/Icons';
import SelectControl from 'src/explore/components/controls/SelectControl';
import {
  AddIconButton,
} from 'src/explore/components/controls/OptionControls';
import { Gutter } from 'antd/lib/grid/row';
import { ColumnMeta, InfoTooltipWithTrigger } from '@superset-ui/chart-controls';
import {
  // @ts-ignore
  MeasureAlertConfigurationEntity,
  ConditionOperatorEnum,
  SignalSectionEntity,
  SignalSectionProps,
  MeasureSeriesItemProps,
  MeasureSeriesItemEntity,
  MeasureSeriesOptionEntity,
  DimensionSeriesValueDetailEntity,
  SeriesEntity,
} from './signalAlertsTypes';
import MeasureSeriesItemControl from './MeasureSeriesItemControl';
import { ImportantAsterisk } from './ImportantAsterisk';
import { getOriginalValue } from './signalSqlGenerator';


const isVerbose = false;

const MEASURE_SERIES_TOOLTIP = 'Select a series to add to detection setting. Press + button to add it.';


// at least need one series selected, even in init mode
const getSelectedSeriesValue = (selectedSeries?: MeasureSeriesOptionEntity) => {
  if (selectedSeries === undefined) {
    return undefined;
  }
  return JSON.stringify(selectedSeries);
};
const getSignalMeasureSeriesOptions = (seriesList: MeasureSeriesOptionEntity[]) => {
  return seriesList.map((measureSeriesOption, index) => {
    const measure = measureSeriesOption.measure;
    if (measureSeriesOption.series === undefined || measureSeriesOption.series.dimension === undefined) {
      return { label: measure, value: JSON.stringify(measureSeriesOption) };
    }
    const dimension = measureSeriesOption.series.dimension;
    const label = `${measure}, ${Object.entries(dimension)
      .map(([key, value]) => `${key} = ${value}`)
      .join(", ")}`;
    return { label, value: JSON.stringify(measureSeriesOption) };
  });
};


const getInitAddedMeasureSeriesConfigList = (addedMeasureSeriesItmeEntity: MeasureSeriesItemEntity[]) => {
  return addedMeasureSeriesItmeEntity || [];
};

export const convertDimensionSeriesToSeriesEntity = (dimensionSeries: DimensionSeriesValueDetailEntity[]) => {
  if (dimensionSeries === undefined || dimensionSeries.length === 0) {
    return undefined;
  }
  // 
  const dimension: { [key: string]: string } = {};
  dimensionSeries.forEach(dimensionSeriesItem => {
    dimension[dimensionSeriesItem.columnName] = dimensionSeriesItem.value;
  });
  const series: SeriesEntity = {
    dimension: dimension,
  };
  return series;
}


export const convertMeasureSeriesItemEntityToMeasureSeriesOptionEntity = (measureSeriesItemEntity: MeasureSeriesItemEntity) => {
  const measureSeriesoptionEntity: MeasureSeriesOptionEntity = {
    measure: measureSeriesItemEntity.measure,
    series: convertDimensionSeriesToSeriesEntity(measureSeriesItemEntity.dimensionSeries),
  };
  return measureSeriesoptionEntity;
}

const getInitSeriesList = (seriesList: MeasureSeriesOptionEntity[], addedMeasureSeriesConfigList: MeasureSeriesItemEntity[]) => {
  if (seriesList.length === 0) {
    return [];
  }

  if (addedMeasureSeriesConfigList.length === 0) {
    return seriesList;
  }

  const preAddedSeriesList = addedMeasureSeriesConfigList.map(measureSeriesItem => {
    return convertMeasureSeriesItemEntityToMeasureSeriesOptionEntity(measureSeriesItem);
  });

  const newSeriesList = seriesList.filter(series => {
    // eslint-disable-next-line prefer-destructuring
    const measure = series.measure;
    const dimension = series.series?.dimension;
    const isExist = preAddedSeriesList.some(preAddedSeries => {
      const preAddedMeasure = preAddedSeries.measure;
      const preAddedDimension = preAddedSeries.series?.dimension;
      if (preAddedDimension && dimension) {
        return measure === preAddedMeasure && Object.keys(dimension).every(key => dimension[key] === preAddedDimension[key]);
      }
      return measure === preAddedMeasure;
    });
    return !isExist;
  });
  return newSeriesList;
}



const SignalSectionControl = memo(function SignalSectionControl(props: SignalSectionProps) {
  // first access, init a default measure-series if possible
  const firstAccess = useRef(true);
  // @ts-ignore
  const [accordion, setAccordion] = useState(true);

  const [selectedSeries, setSelectedSeries] = useState<MeasureSeriesOptionEntity | undefined>(undefined);
  const [addedMeasureSeriesConfigList, setAddedMeasureSeriesConfigList] = useState<MeasureSeriesItemEntity[]>(getInitAddedMeasureSeriesConfigList(props.addedMeasureSeriesConfigList));
  const [seriesList, setSeriesList] = useState(getInitSeriesList(props.seriesList !== undefined ? props.seriesList : [], addedMeasureSeriesConfigList));
  // @ts-ignore
  const [columnsFormatInfo, setColumnsFormatInfo] = useState(props.columnsFormatInfo);

  const [isReadonly, setIsReadonly] = useState(props.isReadonly);

  const handleSelectedSignalMeasureSeriesChange = (value: string) => {
    // update selectedSeries,
    if (value === undefined) {
      setSelectedSeries(undefined);
    } else {
      setSelectedSeries(JSON.parse(value) as MeasureSeriesOptionEntity);
    }
  };

  const generateDimensionSeries = (columnsFormatInfos: ColumnMeta[], selectedSeries: MeasureSeriesOptionEntity) => {
    const dimensionSeries: DimensionSeriesValueDetailEntity[] = [];
    if (selectedSeries?.series?.dimension) {
      const dimension = selectedSeries.series.dimension;
      Object.keys(dimension).forEach(key => {
        const value = dimension[key];
        const columnFormatInfo = columnsFormatInfo.find(columnFormatInfo => columnFormatInfo.column_name === key);
        const type: string = columnFormatInfo?.type || 'STRING';
        dimensionSeries.push({
          columnName: key,
          value: value,
          type: type,
          originalValue: getOriginalValue(value, type)
        });
      });
    }
    return dimensionSeries;
  };

  const handleMeasureSeriesItemAdd = () => {
    // if selectedSeries is undefined, return
    if (selectedSeries === undefined) {
      return;
    }
    // if selectedSeries is not undefined, add selectedSeries to addedMeasureSeriesConfigList, and added series default is expanded
    const newMeasureSeriesItem: MeasureSeriesItemEntity = {
      measure: selectedSeries.measure,
      dimensionSeries: generateDimensionSeries(columnsFormatInfo, selectedSeries),
      wholeMetricConfiguration: {
        conditionOperator: ConditionOperatorEnum.AND,
        smartDetectionCondition: undefined,
        hardThresholdCondition: undefined,
        changeThresholdCondition: undefined,
      },
      seriesOverrideConfigurations: [],
      dimensionGroupOverrideConfigurations: [],
      configType: 'basic',
      isExpand: true,
      index: addedMeasureSeriesConfigList.length,
    };

    const newAddedMeasureSeriesConfigList = [...addedMeasureSeriesConfigList];
    // collapse all old added measure-series CustomCollapse
    if (accordion) {
      newAddedMeasureSeriesConfigList.forEach(measureSeriesItem => {
        // eslint-disable-next-line no-param-reassign
        measureSeriesItem.isExpand = false;
      });
    }
    newAddedMeasureSeriesConfigList.push(newMeasureSeriesItem);
    setAddedMeasureSeriesConfigList(newAddedMeasureSeriesConfigList);

    //   and remove selectedSeries from seriesList
    const newSeriesList = seriesList.filter((series) => {
      if (series.series && selectedSeries.series) {
        const seriesDimension = series.series.dimension;
        const selectedSeriesDimension = selectedSeries.series.dimension;
        if (seriesDimension && selectedSeriesDimension) {
          return series.measure !== selectedSeries.measure
            || !Object.keys(seriesDimension).every(key => seriesDimension[key] === selectedSeriesDimension[key]);
        }
        return false;
      }
      return series.measure !== selectedSeries.measure;
    });
    setSeriesList(newSeriesList);
    // if seriesList is empty, set selectedSeries to undefined
    if (newSeriesList.length === 0) {
      setSelectedSeries(undefined);
    }
    // if seriesList is not empty, set selectedSeries to the first one
    if (newSeriesList.length > 0) {
      setSelectedSeries(newSeriesList[0]);
    }
  };

  const handleMeasureSeriesItemChange = (index: number, props: MeasureSeriesItemProps, newState: MeasureSeriesItemEntity) => {
    // update measure-series collapse panel
    const updatedMeasureSeriesItem = addedMeasureSeriesConfigList.map((measureSeriesItem, iterIndex) => iterIndex === index ? newState : measureSeriesItem);
    updatedMeasureSeriesItem.forEach((measureSeriesItem, iterIndex) => {
      // eslint-disable-next-line no-param-reassign
      measureSeriesItem.index = iterIndex;
    });
    if (accordion) {
      if (newState.isExpand) {
        updatedMeasureSeriesItem.forEach((measureSeriesItem, iterIndex) => {
          // eslint-disable-next-line no-param-reassign, no-unneeded-ternary
          measureSeriesItem.isExpand = iterIndex === index ? true : false;
        });
      }
    }

    console.log(index, newState.index, newState.isExpand, updatedMeasureSeriesItem.map((measureSeriesItem, iterIndex) => [measureSeriesItem.index, iterIndex]));

    setAddedMeasureSeriesConfigList(updatedMeasureSeriesItem);
  };

  const handleMeasureSeriesItemRemove = (index: number) => {
    console.log(`=== handleMeasureSeriesItemRemove, index {index} ===`);
    const oldAddedMeasureSeriesConfigList = addedMeasureSeriesConfigList;
    // delete one measure-series CustomCollapse in addedMeasureSeriesConfigList
    const newAddedMeasureSeriesConfigList = addedMeasureSeriesConfigList.filter((measureSeriesItem, i) => {
      return i !== index;
    });
    newAddedMeasureSeriesConfigList.forEach((measureSeriesItem, iterIndex) => {
      // eslint-disable-next-line no-param-reassign
      measureSeriesItem.index = iterIndex;
    });
    setAddedMeasureSeriesConfigList(newAddedMeasureSeriesConfigList);

    // add removed measure-series to seriesList and if selectedSeries is undefined, set selectedSeries to the first one
    const removedMeasureSeriesItem = oldAddedMeasureSeriesConfigList[index];

    const deletedMeasureSeries = convertMeasureSeriesItemEntityToMeasureSeriesOptionEntity(removedMeasureSeriesItem);

    const newSeriesList = [...seriesList, deletedMeasureSeries];
    if (isVerbose) console.log('newSeriesList', newSeriesList);

    setSeriesList(newSeriesList);
    if (selectedSeries === undefined) {
      setSelectedSeries(deletedMeasureSeries);
    }
  };


  const renderMeasureSeriesList = (addedMeasureSeriesConfigList: MeasureSeriesItemEntity[]) => {
    // if addedMeasureSeriesConfigList is empty, render empty
    if (addedMeasureSeriesConfigList.length === 0) {
      // <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
      return (
        <></>
      );
    }
    // if addedMeasureSeriesConfigList is not empty, render each MeasureSeriesItemControl
    return addedMeasureSeriesConfigList.map((measureSeriesItem, index) => {
      return (
        <div
          css={css`
            padding: ${supersetTheme.gridUnit * 2}px;
            border: 1px solid ${supersetTheme.colors.grayscale.light2}; 
            border-radius: ${supersetTheme.gridUnit}px;
          `}
        >
          <MeasureSeriesItemControl
            {...measureSeriesItem}
            index={index}
            key={index}
            isReadonly={isReadonly}
            onChange={(index, props, newState) => handleMeasureSeriesItemChange(index, props, newState)}
            onRemove={(index) => handleMeasureSeriesItemRemove(index)}
          />
        </div>
      );
    });
  };

  const getData = () => {
    const result: SignalSectionEntity = {
      seriesList: seriesList,
      addedMeasureSeriesConfigList: addedMeasureSeriesConfigList
    };
    return result;
  };

  useEffect(() => {
    props.onChange(props, getData());
  }, [seriesList, addedMeasureSeriesConfigList]);

  useEffect(() => {
    setIsReadonly(props.isReadonly);
  }, [props.isReadonly]);

  // similar with componentDidupdate lifecycle hook
  useEffect(() => {
    if (firstAccess.current === false) {
      return;
    }
    firstAccess.current = false;

    // add first series in seriesList to addedMeasureSeriesConfigList
    if (seriesList && seriesList.length > 0 && addedMeasureSeriesConfigList.length === 0) {
      const firstSeries = seriesList[0];
      const newMeasureSeriesItem: MeasureSeriesItemEntity = {
        measure: firstSeries.measure,
        dimensionSeries: generateDimensionSeries(columnsFormatInfo, firstSeries),
        wholeMetricConfiguration: {
          conditionOperator: ConditionOperatorEnum.AND,
          smartDetectionCondition: undefined,
          hardThresholdCondition: undefined,
          changeThresholdCondition: undefined,
        },
        seriesOverrideConfigurations: [],
        dimensionGroupOverrideConfigurations: [],
        configType: 'basic',
        isExpand: true,
        index: 0,
      };
      setAddedMeasureSeriesConfigList([newMeasureSeriesItem]);
      // remove first series in seriesList
      const newSeriesList = seriesList.filter((series, index) => index !== 0);
      setSeriesList(newSeriesList);
      // if seriesList is empty, set selectedSeries to undefined
      if (newSeriesList.length === 0) {
        setSelectedSeries(undefined);
      }
      // if seriesList is not empty, set selectedSeries to the first one
      if (newSeriesList.length > 0) {
        setSelectedSeries(newSeriesList[0]);
      }
    } else {
      // if seriesList is empty, set selectedSeries to undefined
      if (seriesList.length === 0) {
        setSelectedSeries(undefined);
      }
      // if seriesList is not empty, set selectedSeries to the first one
      if (seriesList.length > 0) {
        setSelectedSeries(seriesList[0]);
      }
    }
  });

  const getSignalMeasureSeriesOptionsCss = (seriesList: MeasureSeriesOptionEntity[]) => {
    const result = getSignalMeasureSeriesOptions(seriesList);
    if (result.length === 0) {
      return css`
        display: none;
      `;
    }
    return css``;
  };


  const theme = supersetTheme;
  const rowCss = css`
    display: flex;
    align-items: center;
    margin-top: ${theme.gridUnit * 2}px;
  `;
  const rowGutter: [Gutter, Gutter] = [16, 16];
  return (
    <div>
      <Row css={rowCss} gutter={rowGutter}>
        <Col span={24}>
          {renderMeasureSeriesList(addedMeasureSeriesConfigList)}
        </Col>
        <Col span={24}
          css={getSignalMeasureSeriesOptionsCss(seriesList)}
        >
          <ImportantAsterisk /> MEASURE SERIES
          <InfoTooltipWithTrigger
            tooltip={MEASURE_SERIES_TOOLTIP}
            css={css`
              margin-left: ${theme.gridUnit}px;
              align-self: center;
            `}
            placement="top"
          />
        </Col>
        <Col span={24}
          css={getSignalMeasureSeriesOptionsCss(seriesList)}
        >
          <div>
            <SelectControl
              options={getSignalMeasureSeriesOptions(seriesList)}
              holder="Select a series"
              value={getSelectedSeriesValue(selectedSeries)}
              onChange={(value: string) => handleSelectedSignalMeasureSeriesChange(value)}
              disabled={isReadonly}
              clearable={false}
            />
            {!isReadonly &&
              <AddIconButton
                onClick={() => handleMeasureSeriesItemAdd()}
                style={{
                  marginTop: '1em',
                }}
              >
                <Icons.PlusLarge
                  iconSize="s"
                  iconColor={theme.colors.grayscale.light5}
                />
              </AddIconButton>
            }
          </div>
        </Col>
      </Row>
    </div>
  );
});


export default withTheme(SignalSectionControl);