/* eslint-disable react/no-unused-state, prefer-destructuring, dot-notation, prettier/prettier, no-unused-vars */
import { ColumnMeta } from '@superset-ui/chart-controls';
import { QueryData, SupersetTheme } from '@superset-ui/core';
// @ts-ignore
import { SignalMeasureContent} from 'src/types/SignalMeasureContent';

export const SINGAL_ALERT_AILAS = 'notify';

export const STRING_TYPE_EMPTY_SPEC = '';

export const TimeGranularity = {
  // HOUR: 'PT1H',
  DAY: 'P1D',
  WEEK: 'P1W',
};

export interface InValue {
  value: string | undefined;
  label: string;
};

export type QueryResponseDataEntity = {
  key: string[];
  values: QueryResponseValueEntity[];
};

export type QueryResponseValueEntity = {
  x: any;
  y: any;
  series: number;
};

export type SignalAlertsProps = {
  label?: string;
  // description?: string;
  placeholder?: string;
  // addTooltip?: string;
  onChange?: (props?:SignalAlertsProps, newValue?: SignalAlertConfigurationEntity) => void;
  value?: SignalAlertConfigurationEntity;
  theme?: SupersetTheme;
  // form_data?: any;
  isDisabled: boolean;
  dbsIdFk?: number;
  queryData: QueryData;
  params:any;
  isChartSaved:boolean;
  chartIsStale:boolean;
  columnsFormatInfo: ColumnMeta[];
  slicePerm: string,
  sliceSchemaPerm: string,
  // refresh data for main page chart list
  listPageRefreshData: () => void;
};

export type SignalMeasureContentEntity = {
  id?: number;
  signalIdFk?: number;
  measure: string;
  dimension?: string;
  query: string;
  dimensionSeries: string;
  detectionSetting: string;
  deleted: number;
};

export type SignalAlertConfigurationEntity = {
  id?: number;
  signalName?: string;
  description?: string;
  subject?: string;
  recipient?: string;
  mode?: string;
  measure?: string;
  dimension?: string;
  query?: string;
  createdBy?: string;
  series?: string;
  status?: number;
  dbsIdFk?: number;
  signalMeasureContentList?: SignalMeasureContentEntity[];
  sliceIdFk?: number;
  ingestionStartTime?: string;
  granularity?: string;
  signalGranularity?: string;
  signalType?: number;
  sliceUrl?: string;
  owners?: OwnerEntity[];
  slice?: SliceEntity;
  params: string;
  queryData: QueryData;
  perm?: string,
  schemaPerm?: string,
  delay?: number;
};

export type OwnerEntity = {
  email?: string,
  firstName?: string,
  id?: number,
  lastName?: string,
  username?: string
};

export type SliceEntity = {
  id?: number;
  sliceName?: string;
  params?: string;
  queryContext?: string;
  owners?: OwnerEntity[]
  datasourceId?: number;
  datasourceName?: string;
  perm?: string;
  schemaPerm?: string;
};

export type SignalAlertConfigurationProps = SignalAlertConfigurationEntity & {
  params?: any;
  queryData: QueryData;
  columnsFormatInfo: ColumnMeta[];
  maxSeriesNumber: number
  isReadonly: boolean;
  onChange: (props: SignalAlertConfigurationProps, newState: SignalAlertConfigurationEntity) => void;
};

// Need to support status of 'disabled' and 'active' 
export type DetailSectionEntity = {
  description?: string;
  ingestionStartTime?: string;
};

export type DetailSectionProps = DetailSectionEntity & {
  createdBy?: string;
  isReadonly: boolean;
  onChange: (props: DetailSectionProps, newState: DetailSectionEntity) => void;
};

export type ScheduleSectionEntity = {
  status: number;
  signalGranularity?: string;
  granularity: string;
  delay: number;
};

export type ScheduleSectionProps = ScheduleSectionEntity & {
  isReadonly: boolean;
  onChange: (props: ScheduleSectionProps, newState: ScheduleSectionEntity) => void;
};

export type SignalSectionEntity = {
  seriesList?: MeasureSeriesOptionEntity[];
  addedMeasureSeriesConfigList: MeasureSeriesItemEntity[];
};

export type ScheduleDelaySettingEntity = {
  delay: number;
}

export type ScheduleDelaySettingProps = ScheduleDelaySettingEntity & {
  isReadonly: boolean;
  onChange: (props: ScheduleDelaySettingProps, newState: ScheduleDelaySettingEntity) => void;
};

export type SignalSectionProps = SignalSectionEntity & {
  isReadonly: boolean;
  onChange: (props: SignalSectionProps, newState: SignalSectionEntity) => void;
  columnsFormatInfo: ColumnMeta[];
};

export type NotificationSectionEntity = {
  recipient?: string;
  mode?: string;
  subject?: string;
};

export type NotificationSectionProps = NotificationSectionEntity & {
  maxRecipientCount?: number;
  isReadonly: boolean;
  onChange: (props: NotificationSectionProps, newState: NotificationSectionEntity) => void;
};

// Look wilson design: 
// -> Page 15: https://microsoft.sharepoint-df.com/:p:/t/ODSPDNA/ERdAtx0X3JlPvU3jDWN7JkUBCKuWFBPRwoVWH1f4moq2bw?e=Y1UVzy
export type MeasureAlertConfigurationEntity = {
  wholeMetricConfiguration?: MeasureAlertWholeMetricConfigurationEntity;
  seriesOverrideConfigurations?: any[];
  dimensionGroupOverrideConfigurations: any[];
};

export type MeasureAlertWholeMetricConfigurationEntity = {
  conditionOperator?: string;
  smartDetectionCondition?: SmartDetectionConditionEntity;
  hardThresholdCondition?: HardThresholdConditionEntity;
  changeThresholdCondition?: ChangeThresholdConditionEntity;
};

export type DimensionSeriesValueDetailEntity = {
  columnName: string;
  value: string;
  type: string;
  originalValue?: number | string
}; 

// export type MeasureAlertSeriesOverrideConfigurationEntity = {
//   series?: SeriesEntity;
//   conditionOperator?: string;
//   smartDetectionCondition?: SmartDetectionConditionEntity;
//   hardThresholdCondition?: HardThresholdConditionEntity;
//   changeThresholdCondition?: ChangeThresholdConditionEntity;
// };

export type MeasureSeriesItemEntity = MeasureAlertConfigurationEntity & {
  index: number;
  measure: string;
  configType: 'basic' | 'series';
  isExpand: boolean;
  dimensionSeries: DimensionSeriesValueDetailEntity[];
};

export type MeasureSeriesItemProps = MeasureSeriesItemEntity & {
  index: number;
  defaultDetectionType?: 'smart' | 'hard' | 'change';
  isReadonly: boolean;
  onChange: (
    index: number,
    props: MeasureSeriesItemProps,
    newState: MeasureSeriesItemEntity,
  ) => void;
  onRemove: (index: number) => void;
};

export type MeasureSeriesOptionEntity = {
  measure: string;
  series?: SeriesEntity;
};

export type SmartDetectionConditionEntity = {
  sensitivity?: number;
  anomalyDetectorDirection?: string;
  suppressCondition?: SuppressConditionEntity;
};

export type SmartDetectionConditionProps = SmartDetectionConditionEntity & {
  isReadonly: boolean;
  onChange: (props: SmartDetectionConditionProps, newState: SmartDetectionConditionEntity) => void;
};

export type HardThresholdConditionEntity = {
  lowerBound?: number;
  upperBound?: number;
  anomalyDetectorDirection?: string;
  suppressCondition?: SuppressConditionEntity;
};

export type HardThresholdConditionProps = HardThresholdConditionEntity & {
  isReadonly: boolean;
  onChange: (props: HardThresholdConditionProps, newState: HardThresholdConditionEntity) => void;
};

export type ChangeThresholdConditionEntity = {
  changePercentage?: number;
  shiftPoint?: number;
  withinRange?: boolean;
  anomalyDetectorDirection?: string;
  suppressCondition?: SuppressConditionEntity;
};

export type ChangeThresholdConditionProps = ChangeThresholdConditionEntity & {
  isReadonly: boolean;
  onChange: (props: ChangeThresholdConditionEntity, newState: ChangeThresholdConditionEntity) => void;
};

export type SeriesEntity = {
  dimension?: Record<string, string>;
};

export type SeriesDefinedProps = SeriesDefinedEntity & {
  isReadonly: boolean;
  dimensions?: string[];
  onChange: (props: SeriesDefinedProps, newState: SeriesDefinedEntity) => void;
};

export type SeriesDefinedEntity = {
  series?: SeriesEntity[];
};

export type SuppressConditionEntity = {
  minNumber: number;
  minRatio: number;
};

export enum AnomalyDetectorDirectionEnum {
  BOTH = 'Both',
  UP = 'Up',
  DOWN = 'Down',
}

export enum ConditionOperatorEnum {
  AND = 'AND',
  OR = 'OR',
}

export enum SignalAlertValueStatusEnum {
  ACTIVE = 1,
  DISABLED = 0,
}

export enum SignalAlertValueModeEnum {
  EMAIL = 'Email',
  TEAMS = 'Teams',
}

export enum SignalAlertValueTypeEnum {
  UNCATEGORIZED = 0,
  ALERT = 1,
}
