/* eslint-disable react/no-unused-state, prefer-destructuring, dot-notation, prettier/prettier, arrow-body-style, object-shorthand, no-unused-vars */
import {
    css,
    supersetTheme,
    t,
    withTheme,
  } from '@superset-ui/core';
import React, { useEffect, useState, memo } from 'react';
import { Col, Row, Select } from 'src/components';
import { Gutter } from 'antd/lib/grid/row';
import { Form } from 'antd';
import { InfoTooltipWithTrigger } from '@superset-ui/chart-controls';
import { SelectValue, LabeledValue } from 'antd/lib/select';
import {
  ScheduleDelaySettingEntity,
  ScheduleDelaySettingProps,
} from './signalAlertsTypes';

import { DEFAULT_DELAY, MAX_DELAY, MIN_DELAY } from './Constant';

const generateNumberRange = (start: number, end: number) => {
  return Array.from({ length: end - start }, (_, index) => index).map((index: number) => { return {label: index, value: index}});
};

const NUMBER_RANGE_0_11 = generateNumberRange(0, 11);
const NUMBER_RANGE_0_23 = generateNumberRange(0, 24);

const parseDelayToDayAndHour = (delay: number) => {
  return {
    day: Math.floor(Math.trunc(delay) / 24),
    hour: Math.trunc(delay) % 24
  };
};

/**
 * Handle signal schedule delay setting, all value will be transform to hours
 * This value is defined by user, default is 4 hours.
 * Please make sure the minimum delay is 4 hours, the maximum delay is 240 hours(10 days).
 */
const ScheduleDelaySettingControl = memo(function ScheduleDelaySettingControl(props: ScheduleDelaySettingProps) {

  const [isReadonly, setIsReadonly] = useState(props.isReadonly);
  // The delay value is in hours, default is 4, can not less than 4, should add validator on Form.Item
  const [delay, setDelay] = useState(props.delay || DEFAULT_DELAY);
  const [hour, setHour] = useState(parseDelayToDayAndHour(delay).hour);
  const [day, setDay] = useState(parseDelayToDayAndHour(delay).day);
  const [showErrorBoundary, setShowErrorBoundary] = useState(false);

  const validateDelay = (value: number) => {
    return !!((value && value.valueOf() >= MIN_DELAY && value.valueOf() <= MAX_DELAY));
  }

  const onChangeHour = (value: any) => {
    setHour(value);
    const newDelay = value + day * 24;
    setDelay(newDelay);
    setShowErrorBoundary(!validateDelay(newDelay));
  };

  const onChangeDay = (value: any) => {
    setDay(value);
    const newDelay = value * 24 + hour;
    setDelay(newDelay);
    setShowErrorBoundary(!validateDelay(newDelay));
  };

  const getData = (): ScheduleDelaySettingEntity => {
    const result: ScheduleDelaySettingEntity = {
      delay
    };
    return result;
  };

  useEffect(() => {
    setIsReadonly(props.isReadonly);
  }, [props.isReadonly]);

  useEffect(() => {
    props.onChange(props, getData());
  }, [delay]);

  const theme = supersetTheme;
  const rowCss = css`
    display: flex;
    align-items: center;
    margin-top: ${theme.gridUnit * 2}px;
  `;
  const rowGutter:[Gutter, Gutter] = [16, 16];
  return (
    <div>
      <Row 
        gutter={rowGutter} 
        css={rowCss}>
        <Col span={24}>
          <Form.Item
            label={
              <>
                <span>DELAY</span>
                <InfoTooltipWithTrigger
                    tooltip="The delay value only support from 1 day(24 hours) to 10 days(240 hours). Default is 1 day. Example: If the delay is 1 day, 01/01's data will report anomaly at around 01/03 00:00:00.
If the delay is 2 days, 01/01's data will report anomaly at around 01/04 00:00:00."
                    css={css`
                      margin-left: ${theme.gridUnit * 2}px;
                    `}
                    placement="top"
                />
              </>
            }
            name="delay"
            required
            initialValue={delay}
          >
            <Form.Item
              css={{width: 120, display: 'inline-flex', alignItems: 'center', alignSelf: 'center'}}
              validateStatus={showErrorBoundary ? 'error' : ''}
            >
              <span css={{width: 120, display: 'inline-flex', alignItems: 'center', alignSelf: 'center'}}>
                <Select
                  css={{width: 100, alignSelf: 'center'}}
                  showSearch
                  labelInValue
                  options={NUMBER_RANGE_0_11}
                  mode='single'
                  value={{label: day, value: day}}
                  onChange={(val:SelectValue) => {
                    if (typeof val === 'object' && 'value' in val && 'label' in val) {
                      const v = val as LabeledValue;
                      onChangeDay(Number.parseInt(v.value as string, 10));
                    } else {
                      onChangeDay(Number.parseInt(val as string, 10));
                    }
                  }}
                  ariaLabel={t('Input day')}
                  placeholder={t('Day')}
                  filterOption
                  showArrow={false}
                  allowNewOptions
                  disabled={isReadonly}
                  allowClear={false}
                />
                <span css={{alignSelf: 'center'}}>&nbsp;DAY</span>
              </span>
            </Form.Item>
            &nbsp;&nbsp;
            <Form.Item
              css={{width: 130, display: 'inline-flex', alignItems: 'center', alignSelf: 'center'}}
              validateStatus={showErrorBoundary ? 'error' : ''}
            >
              <span css={{width: 130, display: 'inline-flex', alignItems: 'center', alignSelf: 'center'}}>
                <Select
                  css={{width: 100, alignSelf: 'center'}}
                  showSearch
                  labelInValue
                  options={NUMBER_RANGE_0_23}
                  mode='single'
                  value={{label: hour, value: hour}}
                  onChange={(val:SelectValue) => {
                    if (typeof val === 'object' && 'value' in val && 'label' in val) {
                      const v = val as LabeledValue;
                      onChangeHour(Number.parseInt(v.value as string, 10));
                    } else {
                      onChangeHour(Number.parseInt(val as string, 10));
                    }
                  }}
                  ariaLabel={t('Input hour')}
                  placeholder={t('Hour')}
                  filterOption
                  showArrow={false}
                  allowNewOptions
                  disabled={isReadonly}
                  allowClear={false}
                />
                <span css={{alignSelf: 'center'}}>&nbsp;HOUR</span>
              </span>
            </Form.Item>
          </Form.Item>
        </Col>
      </Row>
    </div>  
  );
});


export default withTheme(ScheduleDelaySettingControl);